import { Group, rem, Text, useMantineTheme } from '@mantine/core';

const AttributeValueLine = ({ attribute, value, valueColor, Icon }) => {
  const theme = useMantineTheme();
  return (
    <Group position="apart">
      <Group spacing={2} mb={-8}>
        {Icon && (
          <Icon
            size="1.05rem"
            color={theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5]}
            mr={rem(5)}
            stroke={1.5}
          />
        )}
        <Text size="xs">{attribute}</Text>
      </Group>
      <Text size="xs" color={valueColor}>
        {value}
      </Text>
    </Group>
  );
};

export default AttributeValueLine;
