import { useMutation, useQueryClient } from '@tanstack/react-query';
import { renderNotification } from '../../../utils/commons';

const useOptimisticMutation = (queryKey, mutationFn, optimisticUpdaterFn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: mutationFn,
    onMutate: async values => {
      await queryClient.cancelQueries({ queryKey });
      const previousProduct = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, old => optimisticUpdaterFn(old, values));
      return { previousProduct };
    },
    onError: (err, _, context) => {
      console.log('ERROR', err);
      queryClient.setQueryData(queryKey, context.previousProduct);
      renderNotification(err.message, 'error');
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};

export default useOptimisticMutation;
