import { Box, Button, Card, Center, Flex, Grid, Select, Text, Textarea, TextInput } from '@mantine/core';
import CustomDropzone from '../../../../layouts/Dropzone';
import ProductItems from '../../components/productItems';
import { fetchProductAndResources } from '../../../../services/product.service';
import { useQuery } from '@tanstack/react-query';
import { adaptArrayToSelect } from '../../../../services/resources.service';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { NAME_MAX_LENGTH_ERROR, NAME_MIN_LENGTH_ERROR, REQUIRED_ERROR } from '../../../../utils/constants';
import { useForm, yupResolver } from '@mantine/form';
import { useProductItem } from '../../hooks/useProductItem';
import useAuthStore from '../../../../store/auth.store';

const queryKey = ['productResources'];
const PRODUCT_ITEMS_EMPTY_VALUE = [
  { size: '', color: '', purchasePrice: 0, sellingPrice: 0, targetAudiences: [], quantity: 1 },
];

const ProductEdition = ({ product, submitProduct, singleProductQueryKey }) => {
  const token = useAuthStore(state => state.token);
  const { isSuccess, data } = useQuery({
    queryKey,
    queryFn: () => fetchProductAndResources(token),
    staleTime: 24 * 60 * 60 * 1000,
  });

  const [imagesMissing, setImagesMissing] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesFromServer, setFilesFromServer] = useState([]);

  const [isSubmit, setIsSubmit] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);

  const formValidator = Yup.object().shape({
    name: Yup.string().min(3, NAME_MIN_LENGTH_ERROR).max(30, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
    brandName: Yup.string().min(3, NAME_MIN_LENGTH_ERROR).max(30, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
    description: Yup.string(),
    catalog: Yup.string()
      .test('is-valid-catalog', 'Catalogue invalide', function (value) {
        return data.catalogs.some(catalog => catalog.localName === value);
      })
      .required(REQUIRED_ERROR),
    category: Yup.string()
      .test('is-valid-category', 'Categori invalide', function (value) {
        return filteredCategories.some(category => category.name === value);
      })
      .required(REQUIRED_ERROR),
  });

  const form = useForm({
    initialValues: {
      name: '',
      brandName: '',
      description: '',
      category: '',
      catalog: '',
    },
    validate: yupResolver(formValidator),
  });

  useEffect(() => {
    if (isSuccess && !product) {
      setFilteredCategories([...filterCategories(data.categories, data.catalogs[0].name)]);
      form.setFieldValue('catalog', data.catalogs[0].name);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess && product) {
      setFilteredCategories([...filterCategories(data.categories, product.catalog)]);
      form.setValues({ catalog: product.catalog });
      form.setValues({ category: product.category });
      form.setValues({ name: product.name });
      form.setValues({ brandName: product.brandName });
      form.setValues({ description: product.description });
      setFilesFromServer(product.files);
    }
  }, [product, isSuccess]);

  const initProductItems = () => {
    if (product) {
      return product.productItems.map(
        ({ _id, size, color, purchasePrice, sellingPrice, initialQuantity, targetAudiences, product, stock }) => {
          const productItem = {
            _id,
            productId: product,
            stockId: stock,
            size,
            purchasePrice,
            sellingPrice,
            quantity: initialQuantity,
            targetAudiences,
          };
          if (color) {
            productItem.color = color.name;
          }
          return productItem;
        }
      );
    }
    return PRODUCT_ITEMS_EMPTY_VALUE;
  };

  // Product items
  const { productItems, addProductItem, removeProductItem, handleProductItemChange } = useProductItem(
    token,
    initProductItems()
  );

  const onAddProductItem = () => {
    setIsSubmit(false);
    addProductItem();
  };

  const filterCategories = (categories, currentCatalog) => {
    return adaptArrayToSelect(
      categories.filter(category => category.catalog.localName.toLowerCase() === currentCatalog.toLowerCase())
    );
  };

  const validateProductItem = ({ color, size, purchasePrice, sellingPrice, targetAudiences }) => {
    const validateUniversalFields = purchasePrice > 0 && sellingPrice > 0;
    if (form.values.catalog.toLowerCase() === 'accessoirs') {
      return validateUniversalFields && hasConsistentFacultativeAttribute();
    }
    return color && size && targetAudiences.length > 0 && validateUniversalFields;
  };

  const hasConsistentFacultativeAttribute = () => {
    const hasSize = productItems.some(({ size }) => !!size);
    const allHaveSize = productItems.every(({ size }) => !!size);

    const hasColor = productItems.some(({ color }) => !!color);
    const allHaveColor = productItems.every(({ color }) => !!color);

    const hasTargetAudiences = productItems.some(({ targetAudiences }) => targetAudiences.length > 0);
    const allHaveTargetAudiences = productItems.every(({ targetAudiences }) => targetAudiences.length > 0);

    return hasSize === allHaveSize && hasColor === allHaveColor && hasTargetAudiences === allHaveTargetAudiences;
  };

  const checkIfAllProductItemsAreValid = () => {
    const productItemsValid = productItems.every(productItem => validateProductItem(productItem));
    const containFiles = files.length > 0 || filesFromServer.length > 0;
    if (!containFiles) {
      setImagesMissing(true);
    }
    return productItemsValid && containFiles;
  };

  const handleSubmit = async ({ name, brandName, description, category, catalog }) => {
    if (checkIfAllProductItemsAreValid()) {
      setIsSubmit(false);
      const enrichedProductItems = enrichProductItems(productItems);
      await submitProduct({
        name,
        brandName,
        description,
        category,
        catalog,
        files,
        productItems: enrichedProductItems,
      });
    } else {
      setIsSubmit(true);
    }
  };

  const enrichProductItems = productItems => {
    return productItems.map(productItem => {
      if (productItem.color) {
        const colorName = productItem.color;
        const { name, hexValue } = data.colors.find(color => color.name === colorName);
        return { ...productItem, color: { name, hexValue } };
      }
      return productItem;
    });
  };

  const handleCatalogChange = catalog => {
    setFilteredCategories([...filterCategories(data.categories, catalog)]);
    form.setValues({ catalog: catalog });
    form.setValues({ category: '' });
  };

  return (
    <>
      {isSuccess && (
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Grid mt={10}>
            {/* HANDLE CATEGORY */}
            <Grid.Col xs={4}>
              <Text fw={900} size={16}>
                Catalogue
              </Text>
            </Grid.Col>
            <Grid.Col xs={8}>
              <Card withBorder radius="md" shadow="md">
                <Grid>
                  <Grid.Col xs={6}>
                    <Select
                      withAsterisk
                      withinPortal
                      data={adaptArrayToSelect(data.catalogs, 'localName')}
                      placeholder="Vêtements"
                      label="Catalogue"
                      value={form.values.catalog}
                      onChange={handleCatalogChange}
                      error={form.getInputProps('catalog').error}
                    />
                  </Grid.Col>
                  <Grid.Col xs={6}>
                    <Select
                      withAsterisk
                      withinPortal
                      searchable
                      data={filteredCategories}
                      placeholder="Jeans"
                      label="Catégorie"
                      {...form.getInputProps('category')}
                    />
                  </Grid.Col>
                </Grid>
              </Card>
            </Grid.Col>
            {/* HANDLE PRODUCT DETAIL */}
            <Grid.Col xs={4}>
              <Text fw={900} size={16}>
                Détails
              </Text>
              <Text>Veuillez saisir les détails du produit</Text>
            </Grid.Col>
            <Grid.Col xs={8}>
              <Card withBorder radius="md" shadow="md">
                <TextInput label="Nom" placeholder="Veste en cuir" withAsterisk {...form.getInputProps('name')} />
                <TextInput
                  label="Marque"
                  placeholder="Diesel"
                  withAsterisk
                  mt="md"
                  {...form.getInputProps('brandName')}
                />
                <Textarea
                  label="Description"
                  placeholder="Ajouter la description du produit ici"
                  autosize
                  mt="md"
                  minRows={5}
                  {...form.getInputProps('description')}
                />
                <Box mt="md">
                  <Text size="md" m={0}>
                    Images du produit{' '}
                    <Text span inherit c="red">
                      *
                    </Text>
                  </Text>
                  <CustomDropzone
                    images={files}
                    setImages={setFiles}
                    imagesMissing={imagesMissing}
                    setImagesMissing={setImagesMissing}
                    fileFromServerList={filesFromServer}
                    setFilesFromServer={setFilesFromServer}
                    productId={product?._id}
                    queryKey={singleProductQueryKey}
                  />
                </Box>
              </Card>
            </Grid.Col>
            <Grid.Col xs={4}>
              <Text fw={900} size={16}>
                Propriétés
              </Text>
              <Text>Veuillez saisir les propriétés du produit</Text>
            </Grid.Col>
            <Grid.Col xs={8}>
              <Card withBorder radius="md" shadow="md">
                {productItems.map((productItem, index) => (
                  <ProductItems
                    key={index}
                    sizes={adaptArrayToSelect(data.sizes, 'value')}
                    colors={adaptArrayToSelect(data.colors)}
                    productItemList={productItems}
                    productItem={productItem}
                    index={index}
                    onChange={handleProductItemChange}
                    removeProductItem={removeProductItem}
                    isSubmit={isSubmit}
                    setIsSubmit={setIsSubmit}
                    currentCatalog={form.values.catalog}
                  />
                ))}
                <Center>
                  <Button variant="filled" mt="md" onClick={onAddProductItem}>
                    Ajouter propriété
                  </Button>
                </Center>
              </Card>
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end" align="center" mt="xl" mb="xl">
            <Button type="submit" variant="filled">
              {product ? 'Enregistrer les modfications' : 'Créer produit'}
            </Button>
          </Flex>
        </form>
      )}
    </>
  );
};

export default ProductEdition;
