import AppShell from '../../layouts/AppShell';
import { Box, Button, Flex, LoadingOverlay, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import CreateExpense from './pages/CreateExpense';
import { createExpense, getExpenses } from '../../services/expense.service';
import DataTable from '../../components/DataTable';
import { expenseTableColumns } from './expenseTableColumns';
import { calculateExpensesTotalAmount } from '../../utils/commons';
import { useQuery } from '@tanstack/react-query';
import useOptimisticMutation from '../Products/hooks/useOptimisticMutation';
import { ROLE } from '../../utils/constants';
import useAuthStore from '../../store/auth.store';

const queryKey = ['expenses'];

const Expenses = () => {
  const { token, currentUser } = useAuthStore(state => state);
  const {
    isLoading,
    isSuccess,
    data: expenses,
  } = useQuery({
    queryKey,
    queryFn: () => getExpenses(token),
    staleTime: 60 * 60 * 1000,
  });
  const [openExpenseModal, handleExpenseModal] = useDisclosure(false);

  const submitExpense = async values => {
    return createExpense(token, values);
  };

  const optimisticUpdaterFn = (expenses, newExpense) => {
    newExpense.author = currentUser;
    return [newExpense, ...expenses];
  };

  const { mutate: onSubmitExpense } = useOptimisticMutation(queryKey, submitExpense, optimisticUpdaterFn);

  const getTotalAmount = expenses => {
    return calculateExpensesTotalAmount(expenses);
  };

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Dépenses</Text>
        {currentUser.role === ROLE.VENDOR && (
          <Button variant="filled" leftIcon={<IconPlus />} onClick={() => handleExpenseModal.open()}>
            Ajouter une dépense
          </Button>
        )}
      </Flex>

      <Box mt="xl">
        {isSuccess && (
          <DataTable
            tableColumns={expenseTableColumns(getTotalAmount(expenses), currentUser._id)}
            tableData={expenses}
            isLoading={isLoading}
            isError={false}
            enableActions={false}
            enableExpanding={false}
            showColumnFilters={false}
          />
        )}
      </Box>

      <CreateExpense opened={openExpenseModal} closeModal={handleExpenseModal.close} submit={onSubmitExpense} />
      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default Expenses;
