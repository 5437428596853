import { makeRequest } from './api.service';
import { authenticatedRequest } from '../config/axios.config';

export const createVendor = (token, vendor) => {
  return makeRequest(() => authenticatedRequest(token).post(`/vendor-administration`, vendor));
};

export const getAllVendors = token => {
  return makeRequest(() => authenticatedRequest(token).get(`/vendor-administration`));
};

export const handleVendorActivation = (token, vendorAdministrationId, isActive) => {
  return makeRequest(() =>
    authenticatedRequest(token).post(`/vendor-administration/handle-activation`, { isActive, vendorAdministrationId })
  );
};
