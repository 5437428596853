import { Stack, Text } from '@mantine/core';
import { dateTimeToFrenchFormat, dateToFrenchFormat, timeFromDate } from '../../../../utils/date-convert';

export const riderDetailsColumns = [
  {
    accessorKey: 'reference',
    header: 'Référence',
    enableHiding: false,
  },
  {
    accessorKey: 'createdAt',
    header: 'Date',
    Cell: ({ renderedCellValue, row }) => <Text>{dateTimeToFrenchFormat(row.original.createdAt)}</Text>,
  },
  {
    accessorKey: 'address.district',
    header: 'Quartier',
  },
  {
    accessorKey: 'address.complementaryAddress',
    header: 'Destination',
  },
  {
    accessorKey: 'address.city',
    header: 'Ville',
  },
];
