import AppShell from '../../layouts/AppShell';
import { Box, Card, Flex, Menu, Text } from '@mantine/core';
import DataTable from '../../components/DataTable';
import { getOrders } from '../../services/order.service';
import { orderColumns } from './orderColumns';
import { IconFileDescription } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import ProductItemLine from './components/ProductItemLine';
import { useQuery } from '@tanstack/react-query';
import useAuthStore from '../../store/auth.store';

const queryKey = ['orders'];

const CustomerOrders = () => {
  const token = useAuthStore(state => state.token);
  const navigate = useNavigate();

  const { isLoading, data: orders } = useQuery({
    queryKey,
    queryFn: () => getOrders(token),
  });

  const renderDetailPanel = ({ row }) => (
    <Card p="xs" withBorder miw="200%" shadow="md">
      {row.original.orderItems.map((orderItem, index) => (
        <ProductItemLine
          key={orderItem.productItem._id}
          productItem={orderItem.productItem}
          quantity={orderItem.quantity}
          indexInArray={index}
        />
      ))}
    </Card>
  );

  const renderRowActionMenuItems = ({ row }) => {
    return (
      <>
        <Menu.Label>Détails</Menu.Label>
        <Menu.Item
          icon={<IconFileDescription size={14} />}
          onClick={() => navigate(`/customer-orders/${row.original._id}`)}
        >
          Voir détails
        </Menu.Item>
      </>
    );
  };
  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Commandes</Text>
      </Flex>

      <Box p={10}>
        <DataTable
          tableColumns={orderColumns}
          tableData={orders || []}
          isLoading={isLoading}
          isError={false}
          enableActions={true}
          enableExpanding={true}
          showColumnFilters={false}
          renderRowActionMenuItems={renderRowActionMenuItems}
          renderDetailPanel={renderDetailPanel}
        />
      </Box>
    </AppShell>
  );
};

export default CustomerOrders;
