import { Card, Group, Text, useMantineTheme } from '@mantine/core';
import useCardStyles from './style';
import PropTypes from 'prop-types';
import { getFormattedDate } from '../../../../utils/date-convert';
import AttributeValueLine from '../../../../components/AttributeValueLine';

function StoreCard({ name, createdAt, district, complementaryAddress, city, country }) {
  const { classes } = useCardStyles();
  const theme = useMantineTheme();

  return (
    <Card
      withBorder
      radius="md"
      className={classes.card}
      shadow="md"
      sx={{
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
      }}
    >
      <Group position="apart" mt="lg">
        <div>
          <Text fw={500}>{name}</Text>
        </div>
      </Group>

      <Text size="xs" fw={500}>
        Créé le {getFormattedDate(createdAt)}
      </Text>

      <Card.Section className={classes.section} mt="md">
        <AttributeValueLine attribute="Quartier" value={district} />
        <AttributeValueLine attribute="Adresse" value={complementaryAddress} />
        <AttributeValueLine attribute="Ville" value={city} />
        <AttributeValueLine attribute="Pays" value={country} />
      </Card.Section>
    </Card>
  );
}

StoreCard.propTypes = {
  name: PropTypes.string.isRequired,
  district: PropTypes.number.isRequired,
  complementaryAddress: PropTypes.number.isRequired,
  // createdAt: PropTypes.string.isRequired,
  city: PropTypes.number,
  country: PropTypes.number,
};

export default StoreCard;
