import { CRUD_ACTION, METHOD_OF_DELIVERY, PROJECT_STATUS } from './constants';
import { notifications } from '@mantine/notifications';
import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';

export function formatMoneyAmount(amount) {
  const amountFormatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount);
  return amountFormatted.split(',')[0];
}

export function formatToGuineaCurrency(amount) {
  return `${formatCurrency(amount)} FG`;
}

export function formatCurrency(amount) {
  return new Intl.NumberFormat('fr-FR').format(amount);
}

export function calculateValueByPercentage(value, percentage) {
  const result = value * (percentage / 100);
  return result.toFixed(2);
}

export function calculateExpensesTotalAmount(expenses) {
  return expenses.reduce((sum, expense) => sum + expense.amount, 0);
}

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return '';
  return phoneNumber.replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4');
}

export function getAuthorName(author, currentUserId) {
  if (!author) return '';
  return `${author.firstname} ${author.lastname} ${author._id === currentUserId ? '(vous)' : ''}`;
}

export const enrichOrderDataWithCustomerInfo = (orderData, formValues) => {
  const { firstname, lastname, email, phone, complementaryAddress, district, city, country } = formValues;
  const customer = {
    firstname,
    lastname,
    phone,
  };
  if (email) {
    customer.email = email;
  }
  const address = {
    complementaryAddress,
    district,
    city,
    country,
  };
  orderData.customer = customer;
  orderData.address = address;
  return orderData;
};

export function getColorByProjectStatus(status) {
  switch (status) {
    case PROJECT_STATUS.WAITING:
      return 'secondary';
    case PROJECT_STATUS.ONGOING:
      return 'warning';
    case PROJECT_STATUS.FINISHED:
      return 'success';
    case PROJECT_STATUS.CANCELLED:
      return 'error';
    default:
      return '';
  }
}

export const enrichRidersWithSelectAttributes = riders => {
  return riders.map(rider => ({
    ...rider,
    districtsServed: rider.districtsServed.map(district => district.toLowerCase()),
    value: rider.phone,
    label: rider.firstname + ' ' + rider.lastname,
  }));
};

export function renderConfirmModal(modalId, modalText, confirmFunction) {
  modals.openConfirmModal({
    modalId: modalId,
    title: modalText,
    labels: { confirm: 'Supprimer', cancel: 'Annuler' },
    confirmProps: { color: 'red' },
    onConfirm: confirmFunction,
  });
}

export function renderNotification(message, severity) {
  return notifications.show({
    message: <Text dangerouslySetInnerHTML={{ __html: message }}></Text>,
    color: severity === 'success' ? 'green' : 'red',
    sx: theme => ({
      backgroundColor: severity === 'success' ? theme.colors.green[1] : theme.colors.red[1],
      color: theme.white,
    }),
  });
}

export function renderCRUDRequestNotification(entityName, entryName, action, severity) {
  return renderNotification(buildMessage(entityName, entryName, action, severity), severity);
}

export function createFormData(object) {
  const formData = new FormData();
  for (const key in object) {
    if (key === 'files') {
      object[key].forEach(file => formData.append('files', file));
    } else if (key === 'productItems') {
      object[key].forEach((productItem, index) => {
        if (productItem.hasOwnProperty('_id')) {
          formData.append(`productItems[${index}][_id]`, productItem._id);
        }
        if (productItem.size) {
          formData.append(`productItems[${index}][size]`, productItem.size);
        }
        if (productItem.color) {
          formData.append(`productItems[${index}][color][name]`, productItem.color.name);
          formData.append(`productItems[${index}][color][hexValue]`, productItem.color.hexValue);
        }
        if (productItem.hasOwnProperty('targetAudiences')) {
          productItem.targetAudiences.forEach((targetAudience, targetAudienceIndex) => {
            formData.append(`productItems[${index}][targetAudiences][${targetAudienceIndex}]`, targetAudience);
          });
        }
        formData.append(`productItems[${index}][purchasePrice]`, productItem.purchasePrice);
        formData.append(`productItems[${index}][sellingPrice]`, productItem.sellingPrice);
        formData.append(`productItems[${index}][initialQuantity]`, productItem.quantity);
      });
    } else {
      formData.append(key, object[key]);
    }
  }
  return formData;
}

function buildMessage(entityName, entryName, action, severity) {
  switch (action) {
    case CRUD_ACTION.CREATE:
      if (severity === 'success') {
        return `
          Le ${entityName} <b>${entryName}</b> a été créé avec succes !
        `;
      } else {
        return `
            Erreur lors de la création du ${entityName} <b>${entryName}</b>
        `;
      }
    case CRUD_ACTION.UPDATE:
      if (severity === 'success') {
        return `
          Le ${entityName} <b>${entryName}</b> a été mis à jour avec succes !
        `;
      } else {
        return `
          Echec de la mise à jour du ${entityName} <b>${entryName}</b>
        `;
      }
    case CRUD_ACTION.DELETE:
      if (severity === 'success') {
        return `
          Le ${entityName} <b>${entryName}</b> a été supprimé avec succes !
        `;
      } else {
        return `
          Le ${entityName} <b>${entryName}</b> n'a pas pu être supprimé
        `;
      }
    default:
      return '';
  }
}
