import { Box, rem, Switch, useMantineTheme } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { renderNotification } from '../../../../utils/commons';
import { publishProductItem } from '../../../../services/product.service';
import { useQueryClient } from '@tanstack/react-query';
import useAuthStore from '../../../../store/auth.store';

const CustomSwitch = ({ row }) => {
  const token = useAuthStore(state => state.token);
  const theme = useMantineTheme();
  const [checked, setChecked] = useState(row.original.published);
  const queryClient = useQueryClient();

  const handlePublish = async event => {
    const oldChecked = checked;
    const newChecked = event.currentTarget.checked;
    setChecked(newChecked);
    try {
      await publishProductItem(token, row.original.productItemId, newChecked);
      await queryClient.invalidateQueries({ queryKey: ['stock', 'products'] });
    } catch (error) {
      setChecked(oldChecked);
      console.error(error);
      renderNotification('Impossible de mettre à jour le statut de le publication', 'error');
    }
  };

  return (
    <Box>
      <Switch
        checked={checked}
        onChange={handlePublish}
        color="teal"
        size="md"
        thumbIcon={
          checked ? (
            <IconCheck style={{ width: rem(12), height: rem(12) }} color={theme.colors.teal[6]} stroke={3} />
          ) : (
            <IconX style={{ width: rem(12), height: rem(12) }} color={theme.colors.red[6]} stroke={3} />
          )
        }
      />
    </Box>
  );
};

export default CustomSwitch;
