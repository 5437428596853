import { authenticatedRequest, publicDownloadFile } from '../config/axios.config';
import { createFormData } from '../utils/commons';
import FileDownload from 'js-file-download';
import { getCatalogs, getCategories, getColors, getSizes } from './resources.service';
import { makeRequest } from './api.service';

export const createProduct = async (
  token,
  name,
  brandName,
  description,
  category,
  catalog,
  files,
  productItems,
  stockId
) => {
  const query = createFormData({
    name,
    brandName,
    description,
    category,
    catalog,
    files,
    productItems,
    stockId,
  });
  return makeRequest(() =>
    authenticatedRequest(token).post(`/products`, query, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  );
};

export const updateProduct = async (
  token,
  name,
  brandName,
  description,
  category,
  catalog,
  files,
  productItems,
  stockId,
  productId
) => {
  try {
    const query = createFormData({
      name,
      brandName,
      description,
      category,
      catalog,
      files,
      productItems,
      stockId,
    });
    const response = await authenticatedRequest(token).post(`/products/${productId}`, query, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.log('Error', error);
    throw new Error(error.response.data.message);
  }
};

export const publishProductItem = async (token, productItemId, published) => {
  try {
    const response = await authenticatedRequest(token).post(`/product-items/publish-single`, {
      id: productItemId,
      published,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchProductAndResources = async token => {
  try {
    const response = await authenticatedRequest(token).get(`/products/resources`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProductListItems = async token => {
  try {
    const response = await authenticatedRequest(token).get(`/products`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductById = async (token, id) => {
  try {
    const response = await authenticatedRequest(token).get(`/products/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadFile = async (filename, fileOriginalName) => {
  try {
    const response = await publicDownloadFile.get(`/files/single/${filename}`);
    FileDownload(response.data, fileOriginalName);
  } catch (error) {
    throw new Error(error.response.data.errorMessage);
  }
};

export const deleteFile = async (filename, productId) => {
  try {
    const response = await publicDownloadFile.post(`/products/remove-single-file`, { filename, productId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProductItemById = async (token, productItemId, productId, stockId) => {
  return makeRequest(() =>
    authenticatedRequest(token).post(`/products/remove-product-item`, {
      productItemId,
      productId,
      stockId,
    })
  );
};

export const getProductItemListByIds = async (token, productItemIdList) => {
  return makeRequest(() => authenticatedRequest(token).post(`/products/items-for-sell-by-ids`, { productItemIdList }));
};
