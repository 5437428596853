import {
  Button,
  Card,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  rem,
  ScrollArea,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import AppShell from '../../layouts/AppShell';
import ProductItemLine from '../Orders/components/ProductItemLine';
import {
  enrichOrderDataWithCustomerInfo,
  enrichRidersWithSelectAttributes,
  formatToGuineaCurrency,
  renderNotification,
} from '../../utils/commons';
import { IconBasketPlus, IconPlus } from '@tabler/icons-react';
import useCart from '../Orders/hooks/useCart';
import { useNavigate } from 'react-router-dom';
import Delivery from '../Orders/layouts/Delivery';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getRiders } from '../../services/rider.service';
import useCartCustomerAndDelivery from '../Orders/hooks/useCartCustomerAndDelivery';
import { useDisclosure } from '@mantine/hooks';
import {
  INVALID_EMAIL_ERROR,
  INVALID_PHONE_ERROR,
  METHOD_OF_DELIVERY,
  NAME_MAX_LENGTH_ERROR,
  REQUIRED_ERROR,
} from '../../utils/constants';
import { useEffect, useState } from 'react';
import useCartStore from '../../store/cart.store';
import { createOrder } from '../../services/order.service';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import useAuthStore from '../../store/auth.store';
import { useStoreStyle } from '../Stores/style';

const Cart = () => {
  const navigate = useNavigate();
  const token = useAuthStore(state => state.token);
  const { classes } = useStoreStyle();

  const { cartLineList, emptyCart } = useCartStore(state => state);
  const { cartLineProductList, cartTotalQuantity, cartTotalPrice } = useCart();

  const { methodOfDelivery, setMethodOfDelivery, selectedRider, setSelectedRider } = useCartCustomerAndDelivery();

  const [isLoading, { open, close }] = useDisclosure(false);
  const queryClient = useQueryClient();

  const { isSuccess: areRidersLoaded, data: riders } = useQuery({
    queryKey: ['riders'],
    queryFn: () => getRiders(token),
    staleTime: 24 * 60 * 60 * 1000,
  });

  const [deliveryFee, setDeliveryFee] = useState(0);

  useEffect(() => {
    if (methodOfDelivery === METHOD_OF_DELIVERY.DELIVERY) {
      setDeliveryFee(20000);
    } else {
      setDeliveryFee(0);
    }
  }, [methodOfDelivery]);

  const formValidator = Yup.object().shape({
    ...(methodOfDelivery === METHOD_OF_DELIVERY.DELIVERY && {
      firstname: Yup.string().max(20, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
      lastname: Yup.string().max(20, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
      email: Yup.string().email(INVALID_EMAIL_ERROR),
      phone: Yup.string().matches(/^\d+$/, INVALID_PHONE_ERROR).required(REQUIRED_ERROR),
      district: Yup.string().max(50, 'Ne peut dépasser 50 caractères').required(REQUIRED_ERROR),
      complementaryAddress: Yup.string().max(100, 'Ne peut dépasser 100 caractères').required(REQUIRED_ERROR),
      city: Yup.string().max(20, 'Ne peut dépasser 20 caractères').required(REQUIRED_ERROR),
    }),
  });

  const form = useForm({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      district: '',
      complementaryAddress: '',
      city: '',
      country: 'Guinée',
    },
    validate: yupResolver(formValidator),
  });

  const getOrderItems = cartLineList =>
    cartLineList.map(({ productItemId, quantity }) => ({
      productItem: productItemId,
      quantity,
    }));

  const submitOrder = async orderData => {
    try {
      const response = await createOrder(token, orderData);
      emptyCart();
      await queryClient.invalidateQueries({ queryKey: ['orders', 'stocks'] });
      navigate('/customer-orders');
      renderNotification(response.message, 'success');
    } catch (error) {
      console.error(error);
      renderNotification(error.message);
    }
  };

  const onSubmitOrder = async values => {
    if (methodOfDelivery !== METHOD_OF_DELIVERY.PICK_UP && selectedRider === null) {
      renderNotification('Vous devez ajouter un livreur', 'error');
      return;
    }
    open();
    let orderData = {
      orderItems: getOrderItems(cartLineList),
      methodOfDelivery,
    };
    if (methodOfDelivery === METHOD_OF_DELIVERY.DELIVERY) {
      orderData = enrichOrderDataWithCustomerInfo(orderData, values);
      orderData.riderId = selectedRider._id;
    }
    await submitOrder(orderData);
    close();
  };

  const renderProductItems = () => {
    return (
      <Card radius={10} shadow="md">
        <ScrollArea miw="100%" scrollbarSize={2}>
          {cartLineProductList.map((cartlineProduct, index) => (
            <ProductItemLine
              key={cartlineProduct.productItemId}
              productItem={cartlineProduct.productItem}
              quantity={cartlineProduct.quantity}
              indexInArray={index}
              minWidth={600}
              isFromCart={true}
            />
          ))}
        </ScrollArea>
        <Divider mt="xl" mb="xl" size="md" />
        <Grid justify="flex-end">
          <Grid.Col xs={6}>
            <Stack>
              <Group position="apart" miw={250}>
                <Text size={16} c="dimmed">
                  Frais de livraison
                </Text>
                <Text fw={650} size={16}>
                  {formatToGuineaCurrency(deliveryFee)}
                </Text>
              </Group>
              <Group position="apart" miw={250}>
                <Text fw={650} size={16}>
                  Total
                </Text>
                <Text fw={650} size={16}>
                  {formatToGuineaCurrency(cartTotalPrice + deliveryFee)}
                </Text>
              </Group>
            </Stack>
          </Grid.Col>
        </Grid>
      </Card>
    );
  };

  const renderCustomerPanel = () => {
    return (
      <Card radius={10} mt="xl" shadow="md">
        {areRidersLoaded && (
          <Delivery
            riders={enrichRidersWithSelectAttributes(riders)}
            methodOfDelivery={methodOfDelivery}
            setMethodOfDelivery={setMethodOfDelivery}
            deliveryFeeFromServer={20000}
            selectedRider={selectedRider}
            setSelectedRider={setSelectedRider}
            canEdit={true}
          />
        )}

        {methodOfDelivery === METHOD_OF_DELIVERY.DELIVERY && (
          <>
            <Divider variant="dashed" mt="md" mb="md" />

            <Text fw={650} size={18} mb="md">
              Client
            </Text>

            <Grid>
              <Grid.Col xs={6}>
                <TextInput label="Prénom" placeholder="Mamadou" withAsterisk {...form.getInputProps('firstname')} />
              </Grid.Col>
              <Grid.Col xs={6}>
                <TextInput label="Nom" placeholder="Diakhaby" withAsterisk {...form.getInputProps('lastname')} />
              </Grid.Col>
              <Grid.Col xs={6}>
                <TextInput label="Téléphone" placeholder="620123456" withAsterisk {...form.getInputProps('phone')} />
              </Grid.Col>
              <Grid.Col xs={6}>
                <TextInput label="Email (facultatif)" placeholder="test@gmail.com" {...form.getInputProps('email')} />
              </Grid.Col>
            </Grid>

            <Divider variant="dashed" mt="md" mb="md" />

            <Text fw={650} size={18} mb="md">
              Addresse
            </Text>
            <TextInput
              mt="md"
              label="Quartier"
              placeholder="Lambanyi"
              withAsterisk
              {...form.getInputProps('district')}
            />
            <Textarea
              label="Complément d'adresse"
              placeholder="Carrefour cimetiere"
              autosize
              mt="md"
              minRows={2}
              withAsterisk
              {...form.getInputProps('complementaryAddress')}
            />
            <Grid>
              <Grid.Col xs={6}>
                <TextInput mt="md" label="Ville" placeholder="Conakry" withAsterisk {...form.getInputProps('city')} />
              </Grid.Col>
              <Grid.Col xs={6}>
                <TextInput mt="md" label="Pays" disabled withAsterisk {...form.getInputProps('country')} />
              </Grid.Col>
            </Grid>
          </>
        )}
      </Card>
    );
  };

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Panier ({cartTotalQuantity})</Text>
        <Group position="apart">
          <Button variant="filled" leftIcon={<IconPlus />} onClick={() => navigate('/products')}>
            Ajouter produit
          </Button>
        </Group>
      </Flex>

      {cartLineList.length === 0 && (
        <Container className={classes.root}>
          <div className={classes.label}>
            <IconBasketPlus size={rem(180)} />
          </div>
          <Title className={classes.title}>La panier est vide!!!</Title>
          <Group position="center">
            <Button variant="subtle" size="md" onClick={() => navigate('/products')}>
              Ajouter un nouveau produit
            </Button>
          </Group>
        </Container>
      )}
      <form onSubmit={form.onSubmit(onSubmitOrder)}>
        {cartLineProductList.length > 0 && (
          <Grid mt="xl">
            <Grid.Col md={12}>{renderProductItems()}</Grid.Col>
            <Grid.Col md={12}>{renderCustomerPanel()}</Grid.Col>
          </Grid>
        )}
        <Group position="right">
          {cartLineList.length > 0 && (
            <Button type="submit" mt="xl">
              Valider la commande
            </Button>
          )}
        </Group>
      </form>
      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default Cart;
