import { dateToFrenchLongFormat } from '../../utils/date-convert';
import { Badge, Stack, Text } from '@mantine/core';
import { formatPhoneNumber } from '../../utils/commons';
import AccountActivationSwitch from '../../components/AccountActivationSwitch';
import { handleEmployeeActivation } from '../../services/employee.service';

export const employeeTableColumns = [
  {
    header: 'Vendeur',
    Cell: ({ renderedCellValue, row }) => (
      <Stack spacing={0}>
        <Text>
          {row.original.firstname} {row.original.lastname}
        </Text>
        <Text color="dimmed">{row.original.email}</Text>
      </Stack>
    ),
  },
  {
    header: 'Téléphone',
    Cell: ({ renderedCellValue, row }) => (
      <Text>
        <Text color="dimmed">{formatPhoneNumber(row.original.phone)}</Text>
      </Text>
    ),
  },
  {
    header: 'Compte',
    Cell: ({ renderedCellValue, row }) => (
      <Stack spacing={0}>
        <Badge color={row.original.isAccountCreated ? 'green' : ''}>
          {row.original.isAccountCreated ? 'Créé' : 'Non crée'}
        </Badge>
        {row.original.isAccountCreated && <Text>{dateToFrenchLongFormat(row.original.accountCreationDate)}</Text>}
      </Stack>
    ),
  },
  {
    accessorKey: 'isActive',
    header: 'Active',
    size: 100,
    Cell: ({ renderedCellValue, row }) => (
      <AccountActivationSwitch row={row} queryKey={['employees']} handleActivation={handleEmployeeActivation} />
    ),
  },
];
