import { Navigate, Outlet } from 'react-router-dom';
import useAuthStore from '../store/auth.store';

const ProtectedRoutes = ({ allowedRoles }) => {
  const user = useAuthStore(state => state.currentUser);

  const userHasRequiredRole = !!(user && allowedRoles.includes(user?.role));

  return userHasRequiredRole ? <Outlet /> : user ? <Navigate to="/" replace /> : <Navigate to="/login" replace />;
};

export default ProtectedRoutes;
