import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { Box, Group, rem, SimpleGrid, Text, useMantineTheme } from '@mantine/core';
import { IconPhoto, IconUpload, IconX } from '@tabler/icons-react';
import { renderConfirmModal, renderNotification } from '../../utils/commons';
import FileDisplay from '../../components/FileDisplay';
import { deleteFile, downloadFile } from '../../services/product.service';
import { BASE_URL } from '../../config/axios.config';
import useOptimisticMutation from '../../pages/Products/hooks/useOptimisticMutation';

const CustomDropzone = ({
  images,
  setImages,
  fileFromServerList,
  setFilesFromServer,
  imagesMissing,
  setImagesMissing,
  productId,
  queryKey,
}) => {
  const theme = useMantineTheme();

  const optimisticUpdaterFn = (oldProduct, fileToBeRemovedName) => {
    const filesUpdated = oldProduct.files.filter(file => file.name !== fileToBeRemovedName);
    setFilesFromServer(filesUpdated);
    return { ...oldProduct, files: filesUpdated };
  };

  const deleteFileFn = async ({ filename, productId }) => {
    await deleteFile(filename, productId);
  };

  const { mutate } = useOptimisticMutation(queryKey, deleteFileFn, optimisticUpdaterFn);

  const removeFile = (filename, fileOriginalName, isFromServer) => {
    if (isFromServer) {
      const modalText = (
        <div>
          <Text>
            Etes-vous sûr de vouloir supprimer le fichier <b>{`${fileOriginalName} ?`}</b>
          </Text>
        </div>
      );
      const confirmFunction = async () => {
        mutate({ filename, productId });
      };
      renderConfirmModal(filename, modalText, confirmFunction);
    } else {
      const newFiles = images.filter(file => file.name !== filename);
      setImages([...newFiles]);
    }
  };

  const download = async (filename, fileOriginalName) => {
    try {
      await downloadFile(filename, fileOriginalName);
    } catch (error) {
      console.log('Error', error);
    }
  };

  const renderFileCard = (imageUrl, file, isDownloadable, isFromServer) => {
    return (
      <FileDisplay
        key={file.name}
        file={file}
        isDownloadable={isDownloadable}
        imageUrl={imageUrl}
        action={isDownloadable ? download : removeFile}
        isFromServer={isFromServer}
      />
    );
  };

  const displayNewFiles = images.map(image => {
    if (image instanceof Blob || image instanceof File) {
      const imageUrl = URL.createObjectURL(image);
      return renderFileCard(imageUrl, image, false, false);
    } else {
      console.error('Invalid file object:', image);
      return null;
    }
  });

  const displayFilesFromServer = fileFromServerList.map(file => {
    let imageUrl = `${BASE_URL}/files/single/${file.name}`;
    return renderFileCard(imageUrl, file, false, true);
  });

  const addImages = filesUploaded => {
    filesUploaded.forEach(fileUploaded => {
      const fileIndex = images.findIndex(file => file.name === fileUploaded.name && file.size === fileUploaded.size);
      if (fileIndex > -1) {
        renderNotification(`Le fichier <b>${fileUploaded.name}</b> a déjà été ajouté`);
      } else {
        images.push(fileUploaded);
      }
    });
    setImages([...images]);
    setImagesMissing(false);
  };

  return (
    <div>
      <Dropzone
        accept={[MIME_TYPES.jpeg, MIME_TYPES.png]}
        maxSize={5000000}
        onDrop={addImages}
        sx={imagesMissing ? { border: '1px dashed red' } : {}}
      >
        <Group position="center" spacing="xl" style={{ minHeight: rem(120), pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload
              size="3.2rem"
              stroke={1.5}
              color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX size="3.2rem" stroke={1.5} color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]} />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto size="3.2rem" stroke={1.5} />
          </Dropzone.Idle>

          <Box ta="center">
            <Text size="xl">Glisser des fichiers images ici</Text>
            <Text size="sm" color="dimmed" mt={7}>
              Aucun fichier ne doit dépasser 5mb
            </Text>
          </Box>
        </Group>
      </Dropzone>
      {imagesMissing && (
        <Text c="red" size="xs" mt={2}>
          Obligatoire
        </Text>
      )}
      <SimpleGrid
        mt="md"
        cols={6}
        spacing="lg"
        breakpoints={[
          { maxWidth: '62rem', cols: 3, spacing: 'md' },
          { maxWidth: '48rem', cols: 2, spacing: 'sm' },
        ]}
      >
        {displayFilesFromServer}
        {displayNewFiles}
      </SimpleGrid>
    </div>
  );
};

export default CustomDropzone;
