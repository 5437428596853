import { authenticatedRequest, publicRequest } from '../config/axios.config';
import { makeRequest } from './api.service';
import { ROLE } from '../utils/constants';

export const verifyToken = async (accountCreationToken, role) => {
  if (role.toLowerCase() === ROLE.VENDOR.toLowerCase()) {
    return verifyVendorToken(accountCreationToken);
  } else if (role.toLowerCase() === ROLE.EMPLOYEE.toLowerCase()) {
    return verifyEmployeeToken(accountCreationToken);
  }
  return null;
};

export const verifyVendorToken = async accountCreationToken => {
  return makeRequest(() => authenticatedRequest(accountCreationToken).get(`/users/verify-vendor-token`));
};

export const verifyEmployeeToken = async accountCreationToken => {
  return makeRequest(() => authenticatedRequest(accountCreationToken).get(`/users/verify-employee-token`));
};

export const register = async user => {
  return makeRequest(() => publicRequest.post(`/users/auth/signup`, user));
};

export const registerVendor = async (user, token) => {
  return makeRequest(() => authenticatedRequest(token).post(`/users/auth/signup/${ROLE.VENDOR.toLowerCase()}`, user));
};
export const registerEmployee = async (user, token) => {
  return makeRequest(() => authenticatedRequest(token).post(`/users/auth/signup/${ROLE.EMPLOYEE.toLowerCase()}`, user));
};
export const registerSupervisor = async (user, token) => {
  return makeRequest(() =>
    authenticatedRequest(token).post(`/users/auth/signup/${ROLE.SUPERVISOR.toLowerCase()}`, user)
  );
};

export const login = async (email, password) => {
  return makeRequest(() => publicRequest.post(`/users/auth/signin`, { email, password }));
};
