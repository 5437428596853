import { authenticatedRequest } from '../config/axios.config';
import { makeRequest } from './api.service';

const PATH = '/stocks';

export const createStock = async (token, name) => {
  return makeRequest(() => authenticatedRequest(token).post(`${PATH}`, { name }));
  // try {
  //   const response = await authenticatedRequest(token).post(`${PATH}`, { name });
  //   return response.data;
  // } catch (error) {
  //   throw new Error(error.response.data.message);
  // }
};

export const getStocks = async token => {
  try {
    const response = await authenticatedRequest(token).get(`${PATH}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getStockById = async (token, id) => {
  return makeRequest(() => authenticatedRequest(token).get(`${PATH}/${id}`));
};
