import { formatCurrency } from '../../utils/commons';
import { Box, Group, Image, Text } from '@mantine/core';
import { BASE_URL } from '../../config/axios.config';
import CustomSwitch from './components/CustomSwitch';

export const productColumns = [
  // {
  //   accessorKey: '_id',
  //   header: 'ID',
  //   enableHiding: false,
  //   enableEditing: false,
  // },
  {
    accessorKey: 'sku', //access nested data with dot notation
    header: 'Article',
    enableHiding: false,
    minSize: 220,
    Cell: ({ row }) => (
      <Group>
        <Image height={50} width={50} radius="md" src={`${BASE_URL}/files/single/${row.original.images[0].name}`} />
        <Box>
          <Text fw={600}>{row.original.sku}</Text>
          <Text c="dimmed">{row.original.category}</Text>
        </Box>
      </Group>
    ),
  },
  {
    accessorKey: 'name', //access nested data with dot notation
    header: 'Nom',
    size: 150,
  },
  {
    accessorKey: 'brandName', //access nested data with dot notation
    header: 'Marque',
    size: 100,
  },
  {
    accessorKey: 'size', //access nested data with dot notation
    header: 'Taille',
    size: 90,
  },
  {
    accessorKey: 'color.name', //access nested data with dot notation
    header: 'Couleur',
    size: 90,
    Cell: ({ row }) => (
      <Group spacing={4}>
        <Box w={20} h={20} bg={row.original.color?.value} m={0} p={0} sx={{ borderRadius: 50 }}></Box>
        <Text>{row.original.color?.name}</Text>
      </Group>
    ),
  },
  {
    accessorKey: 'initialQuantity', //access nested data with dot notation
    header: 'Quantité',
    size: 100,
  },
  {
    accessorKey: 'quantitySold',
    header: 'Vendus',
    size: 80,
  },
  {
    accessorKey: 'sellingPrice',
    header: 'Prix',
    size: 100,
    Cell: ({ renderedCellValue, row }) => formatCurrency(row.original.sellingPrice) + ' FG',
  },
  {
    accessorKey: 'published',
    header: 'Publié',
    size: 80,
    Cell: ({ renderedCellValue, row }) => <CustomSwitch row={row} />,
  },
];
