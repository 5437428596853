import { Card, Group, Text, useMantineTheme } from '@mantine/core';
import useCardStyles from './style';
import PropTypes from 'prop-types';
import { getFormattedDate } from '../../../../utils/date-convert';
import AttributeValueLine from '../../../../components/AttributeValueLine';
import { formatToGuineaCurrency } from '../../../../utils/commons';
import { ROLE } from '../../../../utils/constants';
import useAuthStore from '../../../../store/auth.store';

function StockCard({ name, createdAt, numberOfProductsInStock, numberOfProductsSold, totalExpenses, totalIncome }) {
  const currentUser = useAuthStore(state => state.currentUser);
  const { classes } = useCardStyles();
  const theme = useMantineTheme();

  const getBenefit = (totalExpenses, totalIncome) => {
    const benefit = totalIncome - totalExpenses;
    return benefit < 0 ? 0 : benefit;
  };

  return (
    <Card
      withBorder
      radius="md"
      className={classes.card}
      shadow="md"
      sx={{
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
      }}
    >
      <Group position="apart" mt="lg">
        <div>
          <Text fw={500}>{name}</Text>
        </div>
      </Group>

      <Text size="xs" fw={500}>
        Créé le {getFormattedDate(createdAt)}
      </Text>

      <Card.Section className={classes.section} mt="md">
        <AttributeValueLine attribute="En stock" value={numberOfProductsInStock} />
        <AttributeValueLine attribute="Vendus" value={numberOfProductsSold} />
        {currentUser.role === ROLE.VENDOR && (
          <AttributeValueLine attribute="Dépenses" value={formatToGuineaCurrency(totalExpenses)} />
        )}
        {currentUser.role === ROLE.VENDOR && (
          <AttributeValueLine attribute="Revenus" value={formatToGuineaCurrency(totalIncome)} />
        )}
        {currentUser.role === ROLE.VENDOR && (
          <AttributeValueLine
            attribute="Bénéfices"
            valueColor={getBenefit(totalExpenses, totalIncome) > 0 ? 'green' : ''}
            value={formatToGuineaCurrency(getBenefit(totalExpenses, totalIncome))}
          />
        )}
      </Card.Section>
    </Card>
  );
}

StockCard.propTypes = {
  name: PropTypes.string.isRequired,
  numberOfProductsInStock: PropTypes.number.isRequired,
  numberOfProductsSold: PropTypes.number.isRequired,
  // createdAt: PropTypes.string.isRequired,
  totalExpenses: PropTypes.number,
  totalIncome: PropTypes.number,
};

export default StockCard;
