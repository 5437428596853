import useOptimisticMutation from '../../Products/hooks/useOptimisticMutation';
import { renderNotification } from '../../../utils/commons';
import { useEffect } from 'react';

const useUpdateOrder = (queryKey, mutateFn, optimisticUpdaterFn) => {
  const { mutate, data, error, isSuccess } = useOptimisticMutation(queryKey, mutateFn, optimisticUpdaterFn);

  useEffect(() => {
    if (data) {
      renderNotification(data.message, 'success');
    }

    if (error) {
      renderNotification(error.message, 'error');
    }
  }, [isSuccess, data, error]);

  return { mutate, isSuccess };
};

export default useUpdateOrder;
