import { Box, Button, Grid, Group, Modal, Textarea, TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { NAME_MAX_LENGTH_ERROR, REQUIRED_ERROR } from '../../../../utils/constants';

function CreateStore({ opened, closeModal, submit }) {
  const formValidator = Yup.object().shape({
    name: Yup.string().max(20, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
    district: Yup.string().max(50, 'Ne peut dépasser 50 caractères').required(REQUIRED_ERROR),
    complementaryAddress: Yup.string().max(100, 'Ne peut dépasser 100 caractères').required(REQUIRED_ERROR),
    city: Yup.string().max(20, 'Ne peut dépasser 20 caractères').required(REQUIRED_ERROR),
  });

  const form = useForm({
    initialValues: {
      name: '',
      district: '',
      complementaryAddress: '',
      city: '',
      country: 'Guinée',
    },
    validate: yupResolver(formValidator),
  });

  const handleSubmit = values => {
    submit(values);
    form.reset();
    closeModal();
  };

  return (
    <Modal
      title={
        <span>
          <h3>Nouvelle boutique</h3>
        </span>
      }
      opened={opened}
      onClose={() => closeModal()}
      keepMounted={false}
    >
      <Box component="form" maw={400} mx="auto" onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          mt="xs"
          label="Nom de la boutique"
          placeholder="Boutique Madina"
          withAsterisk
          {...form.getInputProps('name')}
        />
        <TextInput mt="xs" label="Quartier" placeholder="Lambanyi" withAsterisk {...form.getInputProps('district')} />
        <Textarea
          label="Complément d'adresse"
          placeholder="Carrefour cimetiere"
          autosize
          mt="xs"
          minRows={2}
          withAsterisk
          {...form.getInputProps('complementaryAddress')}
        />
        <Grid>
          <Grid.Col xs={6}>
            <TextInput mt="xs" label="Ville" placeholder="Conakry" withAsterisk {...form.getInputProps('city')} />
          </Grid.Col>
          <Grid.Col xs={6}>
            <TextInput mt="xs" label="Pays" disabled {...form.getInputProps('country')} />
          </Grid.Col>
        </Grid>
        <Group position="right" mt="md">
          <Button type="submit">Valider</Button>
        </Group>
      </Box>
    </Modal>
  );
}

CreateStore.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default CreateStore;
