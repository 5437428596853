import {
  Avatar,
  Badge,
  Box,
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  ScrollArea,
  Select,
  Stack,
  Text,
  Timeline,
} from '@mantine/core';
import AppShell from '../../../../layouts/AppShell';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { cancelOrder, finalizeOrder, getOrderById, payOrderCredit } from '../../../../services/order.service';
import {
  enrichRidersWithSelectAttributes,
  formatCurrency,
  formatPhoneNumber,
  getAuthorName,
  renderNotification,
} from '../../../../utils/commons';
import { METHOD_OF_DELIVERY, OrderStatusMap } from '../../../../utils/constants';
import { IconChevronDown, IconSun } from '@tabler/icons-react';
import Address from '../../layouts/Address';
import { getRiders } from '../../../../services/rider.service';
import Delivery from '../../layouts/Delivery';
import { modals } from '@mantine/modals';
import { dateTimeToFrenchFormat } from '../../../../utils/date-convert';
import ProductItemLine from '../../components/ProductItemLine';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useUpdateOrder from '../../hooks/useUpdateOrder';
import useCartCustomerAndDelivery from '../../hooks/useCartCustomerAndDelivery';
import useAuthStore from '../../../../store/auth.store';

export const ORDER_ORIGIN = {
  WEBSITE: 'SITE_WEB',
  STORE: 'BOUTIQUE',
};

const OrderDetails = () => {
  const { orderId } = useParams();
  const { token, currentUser } = useAuthStore(state => state);

  const matches = useMediaQuery('(min-width: 800px)');

  const [deliveryFee, setDeliveryFee] = useState(0);

  const { methodOfDelivery, setMethodOfDelivery, selectedRider, setSelectedRider } = useCartCustomerAndDelivery();

  const queryClient = useQueryClient();

  const queryKey = ['orders', orderId];
  const { isSuccess: areRidersLoaded, data: riders } = useQuery({
    queryKey: ['riders'],
    queryFn: () => getRiders(token),
  });

  const {
    isLoading,
    isSuccess,
    data: order,
  } = useQuery({
    queryKey,
    queryFn: () => getOrderById(token, orderId),
    enabled: orderId !== undefined,
  });

  useEffect(() => {
    if (isSuccess && order) {
      setDeliveryFee(order.deliveryFee);
      setMethodOfDelivery(order.methodOfDelivery);
      setSelectedRider(order.rider);
    }
  }, [isSuccess, order]);

  const getOrderStatus = currentStatus => {
    const statusAvailableIds = OrderStatusMap[currentStatus].availables;

    const filteredStatusMap = Object.keys(OrderStatusMap)
      .filter(key => statusAvailableIds.includes(OrderStatusMap[key].id))
      .map(key => ({
        value: OrderStatusMap[key].name,
        label: OrderStatusMap[key].value,
      }));

    filteredStatusMap.unshift({
      value: OrderStatusMap[currentStatus].name,
      label: OrderStatusMap[currentStatus].value,
    });
    return filteredStatusMap;
  };

  const handleSelectChange = async currentStatus => {
    if (currentStatus === order.status) {
      return;
    }
    if (currentStatus === 'ANNULE') {
      onOrderCancel(currentStatus);
    } else {
      // Pay order credit
      if (currentStatus === 'PAYE' && order.status === 'CREDIT') {
        await onPayCredit(currentStatus);
        return;
      }

      // Pay order directly or make credit
      await handlePayOrCreditOrder(currentStatus);
    }
    await queryClient.invalidateQueries({ queryKey: ['orders'] });
    await queryClient.invalidateQueries({ queryKey: ['stocks'] });
  };

  const handlePayOrCreditOrder = async currentStatus => {
    if (methodOfDelivery !== METHOD_OF_DELIVERY.PICK_UP && (selectedRider === null || selectedRider === undefined)) {
      renderNotification('Vous devez ajouter un livreur', 'error');
      return;
    }

    const orderFinalizationObject = {
      orderId,
      status: currentStatus,
      methodOfDelivery,
    };

    if (methodOfDelivery !== METHOD_OF_DELIVERY.PICK_UP) {
      orderFinalizationObject.riderId = selectedRider._id;
    }

    // Pay or credit order
    await onPayOrCreditOrder(orderFinalizationObject);
  };

  const payCredit = async () => {
    return payOrderCredit(token, orderId);
  };

  const payOrderCreditOptimisticUpdaterFn = (order, newStatus) => ({ ...order, status: newStatus });
  const { mutate: onPayCredit } = useUpdateOrder(queryKey, payCredit, payOrderCreditOptimisticUpdaterFn);

  const payOrCreditOrder = orderFinalizationObject => {
    return finalizeOrder(token, orderFinalizationObject);
  };

  const payOrCreditOrderOptimisticUpdaterFn = (order, orderFinalizationObject) => ({
    ...order,
    status: orderFinalizationObject.status,
    methodOfDelivery: orderFinalizationObject.methodOfDelivery,
  });

  const { mutate: onPayOrCreditOrder } = useUpdateOrder(
    queryKey,
    payOrCreditOrder,
    payOrCreditOrderOptimisticUpdaterFn
  );

  const onOrderCancel = currentStatus => {
    modals.openConfirmModal({
      modalId: currentStatus,
      title: (
        <div>
          <Text align="center">Etes-vous sûr de vouloir annuler cette commandes ?</Text>
          <Text align="center">Une fois annuler vous ne pouvez plus modifier la commande</Text>
        </div>
      ),
      labels: { confirm: 'Annuler commande', cancel: 'Revenir en arrière' },
      confirmProps: { color: 'red' },
      onConfirm: () => onConfirmCancelOrder(currentStatus),
    });
  };

  const confirmCancelOrder = () => {
    return cancelOrder(token, orderId);
  };

  const cancelOrderOptimisticUpdaterFn = (order, newStatus) => ({ ...order, status: newStatus });

  const { mutate: onConfirmCancelOrder } = useUpdateOrder(queryKey, confirmCancelOrder, cancelOrderOptimisticUpdaterFn);

  const calculateBasketTotalPrice = () => {
    return calculateProductsTotalPrice(order.orderItems) + order.deliveryFee;
  };

  const calculateProductsTotalPrice = orderItems => {
    return orderItems.reduce((sum, orderItem) => sum + orderItem.productItem.sellingPrice * orderItem.quantity, 0);
  };

  const renderProductItems = () => {
    return (
      <Card radius={10} shadow="md">
        <ScrollArea miw="100%" scrollbarSize={2}>
          {order.orderItems.map((orderItem, index) => (
            <ProductItemLine
              key={orderItem.productItem._id}
              productItem={orderItem.productItem}
              quantity={orderItem.quantity}
              indexInArray={index}
            />
          ))}
        </ScrollArea>
        <Divider mt="xl" mb="xl" size="md" />
        <Grid justify="flex-end">
          <Grid.Col xs={6}>
            <Stack>
              <Group position="apart" miw={250}>
                <Text size={16} c="dimmed">
                  Frais de livraison
                </Text>
                <Text fw={650} size={16}>
                  {formatCurrency(deliveryFee)} FG
                </Text>
              </Group>
              <Group position="apart" miw={250}>
                <Text fw={650} size={16}>
                  Total
                </Text>
                <Text fw={650} size={16}>
                  {formatCurrency(calculateBasketTotalPrice())} FG
                </Text>
              </Group>
            </Stack>
          </Grid.Col>
        </Grid>
      </Card>
    );
  };

  const displayCustomerPanel = () => {
    return !(order.origin === ORDER_ORIGIN.STORE && order.methodOfDelivery === METHOD_OF_DELIVERY.PICK_UP);
  };

  const renderCustomerPanel = () => {
    return (
      <Card radius={10} mt="xl" shadow="md">
        <Text fw={650} size={18} mb="md">
          Client
        </Text>
        {!displayCustomerPanel() && <Text>Non saisie</Text>}
        {displayCustomerPanel() && (
          <>
            <Group spacing={0}>
              <Avatar radius="xl" mr={10} />
              <Box>
                <Text>
                  {order.customer.firstname} {order.customer.lastname}
                </Text>
                <Text color="dimmed">{formatPhoneNumber(order.customer.phone)}</Text>
              </Box>
            </Group>

            <Divider variant="dashed" mt="md" mb="md" />

            <Address
              address={order.address}
              orderId={orderId}
              token={token}
              queryKey={queryKey}
              canEdit={order.status === 'ENCOURS'}
            />

            <Divider variant="dashed" mt="md" mb="md" />

            {areRidersLoaded && (
              <Delivery
                riders={enrichRidersWithSelectAttributes(riders)}
                methodOfDelivery={methodOfDelivery}
                setMethodOfDelivery={setMethodOfDelivery}
                deliveryFeeFromServer={order.deliveryFee}
                selectedRider={selectedRider}
                setSelectedRider={setSelectedRider}
                canEdit={order.status === 'ENCOURS'}
              />
            )}
          </>
        )}
      </Card>
    );
  };

  const getActionAuthor = action => {
    return `Par ${getAuthorName(action.author, currentUser._id)}`;
  };
  const renderHistory = () => {
    return (
      <Card radius={10} shadow="md" mt="xl">
        <Text fw={650} size={18} mb="md">
          Historique
        </Text>
        <Timeline bulletSize={24}>
          {order.actions.map(action => (
            <Timeline.Item key={action.date} title={action.title} bullet={<IconSun size="0.8rem" />}>
              <Text c="dimmed" size="sm">
                {dateTimeToFrenchFormat(action.date)}
              </Text>{' '}
              <Text c="dimmed" size="sm">
                {action.comment}
              </Text>{' '}
              <Text c="dimmed" size="sm">
                {getActionAuthor(action)}
              </Text>
            </Timeline.Item>
          ))}
        </Timeline>
      </Card>
    );
  };

  return (
    <AppShell>
      {isSuccess && (
        <>
          <Flex justify="space-between" direction={matches ? 'row' : 'column'} p={5}>
            <Group>
              <Text className="pageTitle" align="center">
                Commande
              </Text>
              <Badge ml="xs" variant="filled" color={OrderStatusMap[order.status].color}>
                {OrderStatusMap[order.status].value}
              </Badge>
            </Group>
            <Select
              data={getOrderStatus(order.status)}
              value={order.status}
              onChange={handleSelectChange}
              radius="md"
              sx={{ width: 120 }}
              rightSection={<IconChevronDown />}
              disabled={order.status === 'ANNULE' || order.status === 'PAYE'}
            />
          </Flex>

          <Grid mt="xl">
            <Grid.Col xs={12}>{renderProductItems()}</Grid.Col>
            <Grid.Col xs={7}>{renderCustomerPanel()}</Grid.Col>
            <Grid.Col xs={5}>{renderHistory()}</Grid.Col>
          </Grid>
        </>
      )}

      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default OrderDetails;
