import { useState } from 'react';
import { deleteProductItemById } from '../../../services/product.service';
import { renderNotification } from '../../../utils/commons';

export const useProductItem = (token, initialValue) => {
  const [productItems, setProductItems] = useState(initialValue);

  const addProductItem = () => {
    setProductItems([
      ...productItems,
      {
        size: '',
        color: '',
        purchasePrice: productItems[0].purchasePrice,
        sellingPrice: productItems[0].sellingPrice,
        targetAudiences: [],
        quantity: 1,
      },
    ]);
  };

  const removeProductItem = async index => {
    const updatedProductItems = [...productItems];
    const deletedProductItemArray = updatedProductItems.splice(index, 1);
    const deletedProductItem = deletedProductItemArray[0];
    if (Object.prototype.hasOwnProperty.call(deletedProductItem, '_id')) {
      try {
        const response = await deleteProductItemById(
          token,
          deletedProductItem._id,
          deletedProductItem.productId,
          deletedProductItem.stockId
        );
        setProductItems(updatedProductItems);
      } catch (error) {
        console.log(error);
        renderNotification(error.message, 'error');
      }
    } else {
      setProductItems(updatedProductItems);
    }
  };

  const handleProductItemChange = (index, updatedProductItem) => {
    const updatedProductItems = [...productItems];
    updatedProductItems[index] = updatedProductItem;
    setProductItems(updatedProductItems);
  };

  return { productItems, addProductItem, removeProductItem, handleProductItemChange };
};
