import { authenticatedRequest } from '../config/axios.config';
import { makeRequest } from './api.service';

export const getOrders = async token => {
  return makeRequest(() => authenticatedRequest(token).get(`/orders`));
};

export const updateCustomerAddress = async (token, address, orderId) => {
  return makeRequest(() => authenticatedRequest(token).put(`/orders/update-customer-address`, { address, orderId }));
};

export const getOrderById = async (token, orderId) => {
  return makeRequest(() => authenticatedRequest(token).get(`/orders/${orderId}`));
};

export const cancelOrder = async (token, orderId) => {
  return makeRequest(() => authenticatedRequest(token).post(`/orders/cancel-order`, { orderId }));
};

export const payOrderCredit = async (token, orderId) => {
  return makeRequest(() => authenticatedRequest(token).post(`/orders/pay-order-credit`, { orderId }));
};

export const finalizeOrder = async (token, orderFinalizationObject) => {
  return makeRequest(() => authenticatedRequest(token).post(`/orders/finalize-order`, orderFinalizationObject));
};

export const createOrder = async (token, order) => {
  // let url = '/orders/from-site';
  // if (methodOfDelivery === METHOD_OF_DELIVERY.PICK_UP) {
  //   url += '/pickup';
  // } else {
  //   url += '/delivery';
  // }
  return makeRequest(() => authenticatedRequest(token).post('/orders/from-store', order));
};
