import AppShell from '../../layouts/AppShell';
import { Box, Button, Flex, Menu, Text } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import { productColumns } from './productColumns';
import { getProductListItems } from '../../services/product.service';
import { useQuery } from '@tanstack/react-query';
import useCartStore from '../../store/cart.store';
import { renderNotification } from '../../utils/commons';
import useAuthStore from '../../store/auth.store';

const queryKey = ['products'];

const Products = () => {
  const token = useAuthStore(state => state.token);
  const { isLoading, isSuccess, data } = useQuery({
    queryKey,
    queryFn: () => getProductListItems(token),
    staleTime: 60 * 60 * 1000,
  });

  const { addProductItemToCart } = useCartStore(state => state);

  const navigate = useNavigate();

  const createOrderFromSelection = table => {
    const selectedProductItems = table.getSelectedRowModel().rows.map(row => row.original);
    let numberOfProductSoldOut = 0;
    selectedProductItems.forEach(productItem => {
      const remainingQuantity = productItem.initialQuantity - productItem.quantitySold;
      if (remainingQuantity === 0) {
        numberOfProductSoldOut++;
      } else {
        addProductItemToCart(productItem.productItemId, 1);
      }
    });
    // All product are sold out
    if (numberOfProductSoldOut === selectedProductItems.length) {
      renderNotification('Tous les produits sélectionnés sont sold out', 'error');
    } else {
      if (numberOfProductSoldOut > 0) {
        renderNotification(
          "Certain(s) produits sélectionnés ne sont plus en stock, ils n'ont pas été ajouter au panier",
          'info'
        );
      }
      navigate('/carts');
    }
  };

  const renderTopToolbarCustomActions = ({ table }) => (
    <Box
      sx={{
        display: 'flex',
        gap: '16px',
        padding: '8px',
        flexWrap: 'wrap',
      }}
    >
      <Button onClick={() => createOrderFromSelection(table)} disabled={!table.getIsAllRowsSelected()}>
        Ajouter au panier
      </Button>
    </Box>
  );

  const renderRowActionMenuItems = ({ row }) => {
    return (
      <>
        <Menu.Item icon={<IconPencil size={14} />} onClick={() => navigate(`/products/${row.original.productId}/edit`)}>
          Modifier
        </Menu.Item>
      </>
    );
  };

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Produits</Text>
      </Flex>

      <Box p={10}>
        {isSuccess && (
          <DataTable
            tableColumns={productColumns}
            tableData={data}
            isLoading={isLoading}
            isError={false}
            enableActions={true}
            enableExpanding={false}
            showColumnFilters={false}
            renderRowActionMenuItems={renderRowActionMenuItems}
            renderTopToolbarCustomActions={renderTopToolbarCustomActions}
            enableRowSelection={true}
          />
        )}
      </Box>
    </AppShell>
  );
};

export default Products;
