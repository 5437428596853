import { Badge, Stack, Text, Tooltip } from '@mantine/core';
import { dateToFrenchFormat, timeFromDate } from '../../utils/date-convert';
import { OrderStatusMap } from '../../utils/constants';
import { formatCurrency, formatPhoneNumber } from '../../utils/commons';

export const orderColumns = [
  {
    accessorKey: 'reference', //access nested data with dot notation
    header: 'Reference',
    enableHiding: false,
    size: 120,
  },
  {
    // accessorKey: 'customer', //access nested data with dot notation
    header: 'Client',
    size: 150,
    Cell: ({ renderedCellValue, row }) => (
      <Stack spacing={0}>
        <Text>{row.original.customer ? row.original.customer : 'Non saisie'}</Text>
        <Text color="dimmed">{formatPhoneNumber(row.original.phone)}</Text>
      </Stack>
    ),
  },
  {
    accessorKey: 'createdAt', //access nested data with dot notation
    header: 'Date',
    size: 120,
    Cell: ({ renderedCellValue, row }) => (
      <Stack spacing={0}>
        <Text>{dateToFrenchFormat(row.original.createdAt)}</Text>
        <Text color="dimmed">{timeFromDate(row.original.createdAt)}</Text>
      </Stack>
    ),
  },
  {
    accessorKey: 'items', //access nested data with dot notation
    header: 'Articles',
    size: 90,
  },
  {
    accessorKey: 'origin', //access nested data with dot notation
    header: 'Origine',
    size: 110,
  },
  {
    accessorKey: 'status', //access nested data with dot notation
    header: 'Statut',
    size: 100,
    Cell: ({ renderedCellValue, row }) => (
      <Tooltip label={OrderStatusMap[row.original.status].value}>
        <Badge variant="filled" color={OrderStatusMap[row.original.status].color}>
          {OrderStatusMap[row.original.status].value}
        </Badge>
      </Tooltip>
    ),
  },
  {
    accessorKey: 'price', //access nested data with dot notation
    header: 'Prix',
    size: 120,
    Cell: ({ renderedCellValue, row }) => formatCurrency(row.original.price) + ' FG',
  },
];
