import {
  IconBuildingStore,
  IconHanger2,
  IconMotorbike,
  IconReceipt2,
  IconShoppingBag,
  IconShoppingCart,
  IconUsers,
} from '@tabler/icons-react';
import Products from '../pages/Products';
import Stocks from '../pages/Stocks';
import CustomerOrders from '../pages/Orders';
import Riders from '../pages/Riders';
import CreateProduct from '../pages/Products/pages/CreateProduct';
import StockDetails from '../pages/Stocks/pages/StockDetails';
import Expenses from '../pages/Expenses';
import OrderDetails from '../pages/Orders/pages/OrderDetails';
import EditProduct from '../pages/Products/pages/EditProduct';
import RiderDetails from '../pages/Riders/pages/RiderDetails';
import Cart from '../pages/Carts';
import Vendors from '../pages/Vendors';
import { ROLE } from '../utils/constants';
import Employees from '../pages/Employees';
import Stores from '../pages/Stores';

export const adminRoutes = [
  {
    type: 'collapse',
    name: 'Vendeurs',
    key: 'vendors',
    icon: IconUsers,
    route: '/vendors',
    allowedRoles: [ROLE.ADMIN, ROLE.SUPERVISOR],
    isProtected: true,
    component: <Vendors />,
  },
];

export const vendorRoutes = [
  {
    type: 'collapse',
    name: 'Employés',
    key: 'employees',
    icon: IconUsers,
    route: '/employees',
    allowedRoles: [ROLE.VENDOR],
    isProtected: true,
    component: <Employees />,
  },
  // {
  //   type: 'collapse',
  //   name: 'Boutiques',
  //   key: 'store',
  //   icon: IconBuildingStore,
  //   route: '/stores',
  //   allowedRoles: [ROLE.VENDOR],
  //   isProtected: true,
  //   component: <Stores />,
  // },
];

const routes = [
  {
    type: 'collapse',
    name: 'Stocks',
    key: 'stocks',
    icon: IconShoppingBag,
    route: '/stocks',
    allowedRoles: [ROLE.VENDOR, ROLE.EMPLOYEE],
    isProtected: true,
    component: <Stocks />,
    children: [
      {
        type: 'collapse',
        name: 'Stock',
        key: 'stocks',
        route: '/stocks/:id',
        allowedRoles: [ROLE.VENDOR, ROLE.EMPLOYEE],
        isProtected: true,
        component: <StockDetails />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Commandes',
    key: 'customer-orders',
    icon: IconShoppingCart,
    route: '/customer-orders',
    allowedRoles: [ROLE.VENDOR, ROLE.EMPLOYEE],
    isProtected: true,
    component: <CustomerOrders />,
    children: [
      {
        type: 'collapse',
        name: 'Détails commande',
        key: 'customer-orders',
        icon: IconReceipt2,
        route: '/customer-orders/:orderId',
        allowedRoles: [ROLE.VENDOR, ROLE.EMPLOYEE],
        isProtected: true,
        component: <OrderDetails />,
      },
      {
        type: 'collapse',
        name: 'Panier',
        key: 'panier',
        icon: IconHanger2,
        route: '/carts',
        allowedRoles: [ROLE.VENDOR, ROLE.EMPLOYEE],
        isProtected: true,
        component: <Cart />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Produits',
    key: 'products',
    icon: IconHanger2,
    route: '/products',
    allowedRoles: [ROLE.VENDOR, ROLE.EMPLOYEE],
    isProtected: true,
    component: <Products />,
    children: [
      {
        type: 'collapse',
        name: 'Produits',
        key: 'productCreate',
        icon: IconHanger2,
        route: '/products/:id/new',
        allowedRoles: [ROLE.VENDOR, ROLE.EMPLOYEE],
        isProtected: true,
        component: <CreateProduct />,
      },
      {
        type: 'collapse',
        name: 'Produits',
        key: 'productEdit',
        icon: IconHanger2,
        route: '/products/:productId/edit',
        allowedRoles: [ROLE.VENDOR, ROLE.EMPLOYEE],
        isProtected: true,
        component: <EditProduct />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Dépenses',
    key: 'expenses',
    icon: IconReceipt2,
    route: '/expenses',
    allowedRoles: [ROLE.VENDOR, ROLE.EMPLOYEE],
    isProtected: true,
    component: <Expenses />,
  },
  {
    type: 'collapse',
    name: 'Livreurs',
    key: 'riders',
    icon: IconMotorbike,
    route: '/riders',
    allowedRoles: [ROLE.VENDOR, ROLE.EMPLOYEE],
    isProtected: true,
    component: <Riders />,
    children: [
      {
        type: 'collapse',
        name: 'Command livrés',
        key: 'rider',
        icon: IconReceipt2,
        route: '/riders/:riderId',
        allowedRoles: [ROLE.VENDOR, ROLE.EMPLOYEE],
        isProtected: true,
        component: <RiderDetails />,
      },
    ],
  },
];

export default routes;
