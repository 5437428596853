import { useEffect, useState } from 'react';
import { Anchor, Group, Image, Navbar, rem } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import { useSidenavStyles } from './style';
import image from '../../assets/logo/Logo.png';
import routes, { adminRoutes, vendorRoutes } from '../../router/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROLE } from '../../utils/constants';
import useAuthStore from '../../store/auth.store';
import useCartStore from '../../store/cart.store';
import useCart from '../../pages/Orders/hooks/useCart';

export function Sidenav() {
  const { classes, cx } = useSidenavStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState('');

  const { currentUser, logoutAction } = useAuthStore(state => state);
  const { emptyCart } = useCart();

  const handleLogout = event => {
    event.preventDefault();
    console.log('Empty cart');
    emptyCart();
    logoutAction();
  };

  useEffect(() => {
    if (pathname) {
      const paths = pathname.split('/');
      setActive(paths[1]);
    }
  }, [pathname]);

  const links = routes =>
    routes.map(item => (
      <a
        className={cx(classes.link, { [classes.linkActive]: item.key === active })}
        href={item.route}
        key={item.key}
        onClick={event => {
          event.preventDefault();
          setActive(item.key);
          navigate(item.route);
        }}
      >
        <item.icon className={classes.linkIcon} stroke={1.5} />
        <span>{item.name}</span>
      </a>
    ));

  return (
    <Navbar width={{ sm: 300 }} p="md" h="100vh" className={classes.root}>
      <Navbar.Section grow>
        <Group className={classes.header} position="apart">
          <Anchor href="/home" className={classes.logoContainer}>
            <Image height={rem(48)} width={150} fit="contain" radius="md" src={image} alt="logo" />
          </Anchor>
        </Group>
        {(currentUser.role === ROLE.VENDOR || currentUser.role === ROLE.EMPLOYEE) && links(routes)}
        {currentUser.role === ROLE.VENDOR && links(vendorRoutes)}
        {(currentUser.role === ROLE.ADMIN || currentUser.role === ROLE.SUPERVISOR) && links(adminRoutes)}
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <a href="/lougin" className={classes.link} onClick={event => handleLogout(event)}>
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Déconnexion</span>
        </a>
      </Navbar.Section>
    </Navbar>
  );
}

export default Sidenav;
