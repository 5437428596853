import { authenticatedRequest } from '../config/axios.config';
import { makeRequest } from './api.service';

export const getExpenseTypes = async token => {
  return makeRequest(() => authenticatedRequest(token).get(`/expense-types`));
};

export const getExpenses = async token => {
  return makeRequest(() => authenticatedRequest(token).get(`/expenses`));
};

export const createExpenseForStock = async (token, expense) => {
  return makeRequest(() => authenticatedRequest(token).post(`/expenses/expense-for-stock`, expense));
};

export const createExpense = async (token, expense) => {
  return makeRequest(() => authenticatedRequest(token).post(`/expenses`, expense));
};
