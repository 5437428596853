import { Badge, Group, Stack, Text } from '@mantine/core';
import { formatPhoneNumber } from '../../utils/commons';

export const riderColumns = [
  {
    accessorKey: 'id',
    header: 'id',
    enableHiding: false,
  },
  {
    accessorKey: 'name',
    header: 'Nom',
    size: 100,
    Cell: ({ renderedCellValue, row }) => (
      <Stack spacing={0}>
        <Text>{row.original.name}</Text>
        <Text color="dimmed">{formatPhoneNumber(row.original.phone)}</Text>
      </Stack>
    ),
  },
  {
    accessorKey: 'nickname',
    header: 'Surnom',
    size: 80,
  },
  {
    accessorKey: 'city',
    header: 'Ville',
    size: 80,
  },
  {
    accessorKey: 'name',
    header: 'Quartiers Desservis',
    size: 150,
    Cell: ({ renderedCellValue, row }) => (
      <Group spacing={4}>
        {row.original.districtsServed.map(district => (
          <Badge variant="filled">{district}</Badge>
        ))}
      </Group>
    ),
  },
  {
    accessorKey: 'numberOfDeliveries', //access nested data with dot notation
    header: 'Nombre de courses',
    size: 100,
  },
];
