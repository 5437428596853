import { useParams } from 'react-router-dom';
import { getProductById, updateProduct } from '../../../../services/product.service';
import { renderCRUDRequestNotification } from '../../../../utils/commons';
import { CRUD_ACTION } from '../../../../utils/constants';
import AppShell from '../../../../layouts/AppShell';
import { Flex, LoadingOverlay, Text } from '@mantine/core';
import ProductEdition from '../../layout/ProductEdition';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useDisclosure } from '@mantine/hooks';
import useAuthStore from '../../../../store/auth.store';

const EditProduct = () => {
  const { productId } = useParams();
  const token = useAuthStore(state => state.token);

  const queryClient = useQueryClient();
  const [isLoading, { open, close }] = useDisclosure(false);

  const { isSuccess, data: product } = useQuery({
    queryKey: ['product', productId],
    queryFn: async () => getProductById(token, productId),
    staleTime: 60 * 60 * 1000,
  });

  const handleSubmit = async ({ name, brandName, description, category, catalog, files, productItems }) => {
    open();
    try {
      // Product always contain at least one product item
      const stockId = product.productItems[0].stock;
      await updateProduct(
        token,
        name,
        brandName,
        description,
        category,
        catalog,
        files,
        productItems,
        stockId,
        productId
      );
      await queryClient.invalidateQueries({ queryKey: ['product', productId] });
      renderCRUDRequestNotification('produit', name, CRUD_ACTION.UPDATE, 'success');
    } catch (error) {
      console.log('Error');
      renderCRUDRequestNotification('produit', name, CRUD_ACTION.UPDATE, 'error');
    }
    close();
  };

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Modifier produit</Text>
      </Flex>
      {isSuccess && (
        <ProductEdition product={product} submitProduct={handleSubmit} singleProductQueryKey={['product', productId]} />
      )}
      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default EditProduct;
