import {
  ActionIcon,
  Burger,
  Container,
  Group,
  Header,
  Indicator,
  MediaQuery,
  useMantineColorScheme,
} from '@mantine/core';
import { IconMoonStars, IconShoppingCart, IconSun, IconUserCircle } from '@tabler/icons-react';
import { useDashboardNavbarStyles } from './style';
import useCartStore from '../../../store/cart.store';
import { useNavigate } from 'react-router-dom';
import useSettingsStore from '../../../store/settings.store';
import useAuthStore from '../../../store/auth.store';
import { ROLE } from '../../../utils/constants';

export function DashboardNavbar({ opened, toggle }) {
  const navigate = useNavigate();
  const toggleDarkMode = useSettingsStore(state => state.toggleDarkMode);

  const { currentUser } = useAuthStore(state => state);
  const cartLineList = useCartStore(state => state.cartLineList);

  const { colorScheme } = useMantineColorScheme();
  const { classes } = useDashboardNavbarStyles();

  const handleColor = theme => {
    return {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    };
  };

  const handleDarkMode = () => {
    toggleDarkMode();
  };

  return (
    <Header height={64} mb={20}>
      <Container fluid className={classes.inner}>
        <MediaQuery styles={{ display: 'none' }} largerThan={1200}>
          <Burger opened={opened} onClick={() => toggle()} size="sm" className={classes.burger} />
        </MediaQuery>

        <Group spacing={5} className={classes.social} position="right" noWrap>
          {currentUser.role !== ROLE.ADMIN && cartLineList.length > 0 && (
            <Indicator inline label={cartLineList.length}>
              <ActionIcon size="lg" sx={handleColor} onClick={() => navigate('/carts')}>
                <IconShoppingCart size="1.3rem" />
              </ActionIcon>
            </Indicator>
          )}
          {cartLineList.length === 0 && (
            <ActionIcon size="lg" sx={handleColor}>
              <IconShoppingCart size="1.3rem" />
            </ActionIcon>
          )}

          <ActionIcon size="lg" sx={handleColor}>
            <IconUserCircle size="1.3rem" />
          </ActionIcon>
          <ActionIcon onClick={handleDarkMode} size="lg" sx={handleColor}>
            {colorScheme === 'dark' ? <IconSun size="1.3rem" /> : <IconMoonStars size="1.3rem" />}
          </ActionIcon>
        </Group>
      </Container>
    </Header>
  );
}
