import { Navigate, Route, Routes } from 'react-router-dom';
import Register from './pages/authentication/Register';
import Login from './pages/authentication/Login';
import { ColorSchemeProvider, MantineProvider, rem } from '@mantine/core';
import { useEffect, useState } from 'react';
import ProtectedRoute from './router/protected-route';
import routes, { adminRoutes } from './router/routes';
import { ROLE } from './utils/constants';
import useSettingsStore from './store/settings.store';
import Employees from './pages/Employees';
import Stores from './pages/Stores';

function App() {
  const darkMode = useSettingsStore(state => state.darkMode);

  const [colorScheme, setColorScheme] = useState('light');

  useEffect(() => {
    if (darkMode) {
      setColorScheme('dark');
    } else {
      setColorScheme('light');
    }
  }, [darkMode]);

  const renderRoutes = routes =>
    routes
      .map(route => {
        const routesList = [];
        routesList.push(renderRoute(route));
        if (route.children) {
          route.children.forEach(child => {
            routesList.push(renderRoute(child));
          });
        }
        return routesList;
      })
      .flat();

  const renderRoute = route => {
    return <Route key={route.key} path={route.route} element={route.component} />;
  };

  const toggleColorScheme = value => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          colorScheme,
          loader: 'bars',
          globalStyles: theme => ({
            '*, *::before, *::after': {
              boxSizing: 'border-box',
            },

            body: {
              ...theme.fn.fontStyles(),
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : '#F8F9FA',
              color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
              lineHeight: theme.lineHeight,
            },

            '.your-class': {
              backgroundColor: 'red',
            },

            '#your-id > [data-active]': {
              backgroundColor: 'pink',
            },

            '.pageTitle': {
              color: theme.colorScheme === 'dark' ? theme.white : theme.black,
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontSize: rem(30),
              lineHeight: 1.2,
              fontWeight: 700,

              [theme.fn.smallerThan('xs')]: {
                fontSize: rem(18),
              },
            },
          }),
          other: {
            pageTitle: theme => ({
              color: theme.colorScheme === 'dark' ? theme.white : theme.black,
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontSize: rem(44),
              lineHeight: 1.2,
              fontWeight: 700,

              [theme.fn.smallerThan('xs')]: {
                fontSize: rem(26),
              },
            }),
          },
        }}
      >
        <Routes>
          {/* Protected routes */}
          <Route element={<ProtectedRoute allowedRoles={[ROLE.VENDOR, ROLE.EMPLOYEE]} />}>{renderRoutes(routes)}</Route>
          <Route element={<ProtectedRoute allowedRoles={[ROLE.ADMIN, ROLE.SUPERVISOR]} />}>
            {renderRoutes(adminRoutes)}
          </Route>
          <Route element={<ProtectedRoute allowedRoles={[ROLE.VENDOR]} />}>
            <Route path="/employees" element={<Employees />} />;
            <Route path="/stores" element={<Stores />} />;
          </Route>

          {/* Public routes */}
          <Route path="/register" element={<Register />} />
          <Route path="/register/:role/:accountCreationToken" element={<Register />} />
          <Route path="/login" element={<Login />} />

          {/* Default route */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
