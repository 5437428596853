import AppShell from '../../../../layouts/AppShell';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Box, Group, LoadingOverlay, Text } from '@mantine/core';
import { getRiderDetailById } from '../../../../services/rider.service';
import DataTable from '../../../../components/DataTable';
import { riderDetailsColumns } from './riderDetailsColumns';
import useAuthStore from '../../../../store/auth.store';

const RiderDetails = () => {
  const { riderId } = useParams();
  const token = useAuthStore(state => state.token);

  const {
    isSuccess,
    isLoading,
    data: riderInfo,
  } = useQuery({
    queryKey: ['riders', riderId],
    queryFn: () => getRiderDetailById(token, riderId),
  });

  const getRiderName = riderInfo => {
    const { firstname, lastname, nickname } = riderInfo;
    return `${firstname} ${lastname} ${nickname ? '(' + nickname + ')' : ''}`;
  };

  return (
    <AppShell>
      {isSuccess && (
        <>
          <Group p={5}>
            <Text className="pageTitle" align="center">
              Livraisons de {getRiderName(riderInfo)}
            </Text>
          </Group>

          <Box p={10}>
            <DataTable
              tableColumns={riderDetailsColumns}
              tableData={riderInfo.orderList}
              isLoading={isLoading}
              isError={false}
              enableActions={false}
              enableExpanding={false}
              showColumnFilters={false}
            />
          </Box>
        </>
      )}

      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default RiderDetails;
