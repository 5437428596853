import AppShell from '../../../../layouts/AppShell';
import {
  Accordion,
  Box,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Menu,
  rem,
  SimpleGrid,
  Text,
  useMantineColorScheme,
} from '@mantine/core';
import { IconCoin, IconHanger2, IconPencil, IconPlus, IconReceipt2, IconShoppingBag } from '@tabler/icons-react';
import StatsCard from '../../components/StatsCard';
import { useNavigate, useParams } from 'react-router-dom';
import { getStockById } from '../../../../services/stock.service';
import { useDisclosure } from '@mantine/hooks';
import CreateExpense from '../../../Expenses/pages/CreateExpense';
import { createExpenseForStock } from '../../../../services/expense.service';
import DataTable from '../../../../components/DataTable';
import { expenseTableColumns } from '../../expenseTableColumns';
import { calculateExpensesTotalAmount } from '../../../../utils/commons';
import { productColumns } from '../../../Products/productColumns';
import { useQuery } from '@tanstack/react-query';
import useOptimisticMutation from '../../../Products/hooks/useOptimisticMutation';
import { ROLE } from '../../../../utils/constants';
import useAuthStore from '../../../../store/auth.store';

const statsData = {
  numberOfProductsInStock: {
    title: 'Stocks',
    icon: IconHanger2,
    description: "Nombre actuel d'articles en stock",
    isCurrency: false,
    allowedRoles: [ROLE.VENDOR, ROLE.EMPLOYEE],
  },
  numberOfProductsSold: {
    title: 'Vendus',
    icon: IconShoppingBag,
    description: "Nombre actuel d'articles vendu",
    isCurrency: false,
    allowedRoles: [ROLE.VENDOR, ROLE.EMPLOYEE],
  },
  totalExpenses: {
    title: 'Dépenses',
    icon: IconReceipt2,
    description: "Dépenses + prix d'achats des articles",
    isCurrency: true,
    allowedRoles: [ROLE.VENDOR],
  },
  totalIncome: {
    title: 'Revenus',
    icon: IconCoin,
    description: 'Revenus totals générés',
    isCurrency: true,
    allowedRoles: [ROLE.VENDOR],
  },
};

const StockDetails = () => {
  const { id } = useParams();
  const { token, currentUser } = useAuthStore(state => state);

  const {
    isLoading,
    isSuccess,
    data: stock,
  } = useQuery({
    queryKey: ['stock', id],
    queryFn: async () => getStockById(token, id),
    staleTime: 60 * 60 * 1000,
  });

  const navigate = useNavigate();
  const { colorScheme } = useMantineColorScheme();

  const [openExpenseModal, handleExpenseModal] = useDisclosure(false);

  const submitExpense = async values => {
    const expenseToServer = {
      ...values,
      stockId: id,
    };
    return await createExpenseForStock(token, expenseToServer);
  };

  const optimisticUpdaterFn = (stock, newExpense) => {
    newExpense.author = currentUser;
    return { ...stock, expenses: [newExpense, ...stock.expenses] };
  };

  const { mutate } = useOptimisticMutation(['stock', id], submitExpense, optimisticUpdaterFn);

  const renderRowActionMenuItems = ({ row }) => {
    return (
      <>
        <Menu.Item icon={<IconPencil size={14} />} onClick={() => navigate(`/products/${row.original.productId}/edit`)}>
          Modifier
        </Menu.Item>
      </>
    );
  };

  const getBenefit = stats => {
    const benefit = stats.totalIncome - stats.totalExpenses;
    return benefit < 0 ? 0 : benefit;
  };

  const renderStats = stats => {
    return Object.keys(stats).map(attribute => {
      const { title, icon, description, isCurrency, allowedRoles } = statsData[attribute];
      const value = stats[attribute];
      if (allowedRoles.includes(currentUser.role)) {
        return (
          <StatsCard
            title={title}
            Icon={icon}
            value={value}
            valueColor={title === 'Revenus' && getBenefit(stats) > 0 ? 'green' : ''}
            isCurrency={isCurrency}
            description={description}
            key={title}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <AppShell>
      <Flex justify="space-between" wrap="wrap" align="center" direction="row" p={5}>
        <Text className="pageTitle">{isSuccess ? stock.name : ''}</Text>
        <Group position="apart" mt={10}>
          <Button variant="filled" leftIcon={<IconPlus />} onClick={() => navigate(`/products/${id}/new`)}>
            Ajouter un produit
          </Button>
          <Button variant="filled" leftIcon={<IconPlus />} onClick={() => handleExpenseModal.open()}>
            Ajouter une dépense
          </Button>
        </Group>
      </Flex>
      <Box mt="xl">
        <SimpleGrid
          cols={4}
          breakpoints={[
            { maxWidth: 'md', cols: 2 },
            { maxWidth: 'xs', cols: 1 },
          ]}
        >
          {isSuccess && renderStats(stock.stats)}
        </SimpleGrid>
      </Box>

      <Accordion color="white" mt="xl" variant="separated" defaultValue="products" transitionDuration={1000}>
        <Accordion.Item bg={colorScheme === 'light' ? 'white' : ''} value="products">
          <Accordion.Control icon={<IconHanger2 size={rem(20)} />}>
            <Text fw={650} size={rem(20)}>
              Produits
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            {isSuccess && (
              <DataTable
                tableColumns={productColumns}
                tableData={stock.productItems}
                isLoading={isLoading}
                isError={false}
                enableActions={true}
                enableExpanding={false}
                showColumnFilters={false}
                renderRowActionMenuItems={renderRowActionMenuItems}
              />
            )}
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item bg={colorScheme === 'light' ? 'white' : ''} value="expenses">
          <Accordion.Control icon={<IconReceipt2 size={rem(20)} />}>
            <Text fw={650} size={rem(20)}>
              Dépenses
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            {isSuccess && (
              <DataTable
                tableColumns={expenseTableColumns(calculateExpensesTotalAmount(stock.expenses), currentUser._id)}
                tableData={stock.expenses}
                isLoading={isLoading}
                isError={false}
                enableActions={false}
                enableExpanding={false}
                showColumnFilters={false}
              />
            )}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <CreateExpense opened={openExpenseModal} closeModal={handleExpenseModal.close} submit={mutate} />
      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default StockDetails;
