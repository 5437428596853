import AppShell from '../../layouts/AppShell';
import { Button, Container, Flex, Grid, Group, LoadingOverlay, rem, Text, Title } from '@mantine/core';
import { IconBasketPlus, IconPlus } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import CreateStore from './pages/CreateStore';
import { useQuery } from '@tanstack/react-query';
import useOptimisticMutation from '../Products/hooks/useOptimisticMutation';
import useAuthStore from '../../store/auth.store';
import { createStore, getStores } from '../../services/store.service';
import StoreCard from './components/StoreCard';
import { useStoreStyle } from './style';

const queryKey = ['stores'];

const Stores = () => {
  const { classes } = useStoreStyle();
  const { token } = useAuthStore(state => state);
  const {
    isLoading,
    isSuccess,
    data: stores,
  } = useQuery({
    queryKey,
    queryFn: () => getStores(token),
    staleTime: 24 * 60 * 60 * 1000,
  });
  const [openStoreModal, handleStoreModal] = useDisclosure(false);

  const submitStore = async values => {
    return createStore(token, values);
  };

  const optimisticUpdaterFn = (stores, newStore) => {
    const optimisticStore = { ...newStore };
    optimisticStore.createdAt = Date.now();
    optimisticStore._id = Math.round(Math.random() * 10000);
    console.log('optimisticStore', optimisticStore);
    return [optimisticStore, ...stores];
  };

  const { mutate: onSubmitStore } = useOptimisticMutation(queryKey, submitStore, optimisticUpdaterFn);

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Boutiques</Text>

        <Button variant="filled" leftIcon={<IconPlus />} onClick={() => handleStoreModal.open()}>
          Ajouter une dépense
        </Button>
      </Flex>

      {isSuccess && stores.length === 0 && (
        <Container className={classes.root}>
          <div className={classes.label}>
            <IconBasketPlus size={rem(180)} />
          </div>
          <Title className={classes.title}>Vous n'avez pas encore de boutique</Title>
          <Group position="center">
            <Button variant="subtle" size="md" onClick={() => handleStoreModal.open()}>
              Ajouter une nouvelle stock
            </Button>
          </Group>
        </Container>
      )}

      <Grid gutter="lg" align="stretch" p={10}>
        {isSuccess &&
          stores.map(({ _id, name, createdAt, district, complementaryAddress, city, country }) => (
            <Grid.Col md={6} lg={4} key={_id}>
              <StoreCard
                name={name}
                createdAt={createdAt}
                district={district}
                complementaryAddress={complementaryAddress}
                city={city}
                country={country}
              />
            </Grid.Col>
          ))}
      </Grid>

      <CreateStore opened={openStoreModal} closeModal={handleStoreModal.close} submit={onSubmitStore} />
      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default Stores;
