import { authenticatedRequest } from '../config/axios.config';

export const getCatalogs = async token => {
  try {
    const response = await authenticatedRequest(token).get(`/catalogs`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getCategories = async token => {
  try {
    const response = await authenticatedRequest(token).get(`/categories`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getSizes = async token => {
  try {
    const response = await authenticatedRequest(token).get(`/sizes`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getColors = async token => {
  try {
    const response = await authenticatedRequest(token).get(`/colors`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const adaptArrayToSelect = (array, attributeName = 'name') => {
  return array.map(item => {
    item.value = item[attributeName];
    item.label = item[attributeName];
    return item;
  });
};
