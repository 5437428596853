import { Box, Group, SegmentedControl, Select, Text } from '@mantine/core';
import { forwardRef, useState } from 'react';
import { IconArrowsExchange } from '@tabler/icons-react';
import { METHOD_OF_DELIVERY } from '../../../../utils/constants';
import useSettingsStore from '../../../../store/settings.store';

const SelectItem = forwardRef(
  ({ _id, firstname, lastname, nickname, email, phone, districtsServed, ...others }, ref) => {
    return (
      <div ref={ref} {...others} key={_id}>
        <Group noWrap m={1} ml={10}>
          <div>
            <Text size="sm">
              {firstname} {lastname} {nickname ? `(${nickname})` : ''}
            </Text>
            <Group>
              <Text color="dimmed">Quartiers desservis: {districtsServed.join(', ')}</Text>
            </Group>
            <Text size="xs" opacity={0.65}>
              Téléphone : {phone}
            </Text>
          </div>
        </Group>
      </div>
    );
  }
);

const Delivery = ({ riders, selectedRider, setSelectedRider, methodOfDelivery, setMethodOfDelivery, canEdit }) => {
  const darkMode = useSettingsStore(state => state.darkMode);
  const [riderPhone, _] = useState('');

  const handleSelectChange = riderPhone => {
    const foundedRider = riders.find(rider => rider.phone === riderPhone);
    if (foundedRider) {
      setSelectedRider(foundedRider);
    }
  };

  return (
    <>
      <Text fw={650} size={18} mb="md">
        Livraison
      </Text>
      <SegmentedControl
        w="100%"
        value={methodOfDelivery}
        onChange={setMethodOfDelivery}
        disabled={!canEdit}
        data={[
          { label: 'Sur place', value: METHOD_OF_DELIVERY.PICK_UP },
          { label: 'A livrer', value: METHOD_OF_DELIVERY.DELIVERY },
        ]}
      />
      {methodOfDelivery === METHOD_OF_DELIVERY.DELIVERY && (
        <>
          {!selectedRider && (
            <Box>
              <Select
                mt="md"
                withAsterisk
                searchable
                allowDeselect
                clearable
                data={riders}
                maxDropdownHeight={180}
                placeholder="Taper le mail ou le surnom"
                label="Choisir un livreur"
                itemComponent={SelectItem}
                value={riderPhone}
                onChange={handleSelectChange}
                disabled={!canEdit}
                filter={(value, item) =>
                  item?.firstname?.toLowerCase().includes(value?.toLowerCase().trim()) ||
                  item?.lastname?.toLowerCase().includes(value?.toLowerCase().trim()) ||
                  item?.nickname?.toLowerCase().includes(value?.toLowerCase().trim()) ||
                  item?.districtsServed?.some(item => item?.toLowerCase().includes(value?.toLowerCase().trim()))
                }
              />
            </Box>
          )}

          {selectedRider && (
            <Box bg={darkMode ? 'dark.7' : 'gray.1'} p={10} sx={{ borderRadius: 10 }} mt="xl">
              <Group position="apart">
                <Text size="sm" fw={650}>
                  {selectedRider.firstname} {selectedRider.lastname}{' '}
                  {selectedRider.nickname && `(${selectedRider.nickname})`}
                </Text>
                {canEdit && (
                  <Group position="apart" sx={{ cursor: 'pointer' }} onClick={() => setSelectedRider(null)}>
                    <IconArrowsExchange color="red" />
                    <Text color="red" fw={650}>
                      Changer
                    </Text>
                  </Group>
                )}
              </Group>

              {selectedRider.nickname && (
                <Group mt="md">
                  <Text size="sm" color="dimmed">
                    Quartiers desservis :
                  </Text>
                  <Text size="sm" fw={650}>
                    {selectedRider.districtsServed.join(', ')}
                  </Text>
                </Group>
              )}
              <Group mt="sx">
                <Text size="sm" color="dimmed">
                  Téléphone :
                </Text>
                <Text size="sm" fw={650}>
                  {selectedRider.phone}
                </Text>
              </Group>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Delivery;
