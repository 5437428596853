import { useEffect, useState } from 'react';
import { METHOD_OF_DELIVERY } from '../../../utils/constants';

const useCartCustomerAndDelivery = () => {
  const [methodOfDelivery, setMethodOfDelivery] = useState(METHOD_OF_DELIVERY.PICK_UP);
  const [selectedRider, setSelectedRider] = useState(null);
  const [deliveryFee, setDeliveryFee] = useState(0);

  useEffect(() => {
    if (methodOfDelivery === METHOD_OF_DELIVERY.DELIVERY) {
      setDeliveryFee(20000);
    } else {
      setDeliveryFee(0);
    }
  }, [methodOfDelivery]);

  return {
    methodOfDelivery,
    setMethodOfDelivery,
    selectedRider,
    setSelectedRider,
    deliveryFee,
    setDeliveryFee,
  };
};

export default useCartCustomerAndDelivery;
