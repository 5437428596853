import { Box, Checkbox, Grid, Group, Indicator, NumberInput, Select, Text } from '@mantine/core';
import { QuantityInput } from '../../../../components/QuantityInput';
import { IconTrash } from '@tabler/icons-react';
import { REQUIRED_ERROR } from '../../../../utils/constants';
import { useSetState } from '@mantine/hooks';
import { useEffect } from 'react';

const ERRORS_INITIAL_STATE = {
  purchasePriceErrorText: '',
  sellingPriceErrorText: '',
  sizeErrorText: '',
  colorErrorText: '',
  targetAudiencesErrorText: '',
};

const ProductItem = ({
  productItemList,
  productItem,
  sizes,
  colors,
  index,
  onChange,
  removeProductItem,
  isSubmit,
  setIsSubmit,
  currentCatalog,
}) => {
  // Validators
  const [
    { purchasePriceErrorText, sellingPriceErrorText, sizeErrorText, colorErrorText, targetAudiencesErrorText },
    setErrors,
  ] = useSetState(ERRORS_INITIAL_STATE);

  const validatePrice = (price, attribute) => {
    const errorText = price > 0 ? '' : REQUIRED_ERROR;
    setErrors({ [attribute]: errorText });
  };

  const validateColorOrSize = (value, attribute) => {
    const errorText = value ? '' : REQUIRED_ERROR;
    setErrors({ [attribute]: errorText });
  };

  const validateTargetAudiences = array => {
    const errorText = array.length !== 0 ? '' : REQUIRED_ERROR;
    setErrors({ targetAudiencesErrorText: errorText });
  };

  // The aim of this method is to check all product items have the same attributes
  // if we set the color for one, all of them have to have color
  const validateFacultativeAttributeCoherence = () => {
    const isAtLeastOneSizeSet = productItemList.some(item => item.size);
    const isAtLeastOneColorSet = productItemList.some(item => item.color);
    const isAtLeastOneTargetAudiencesSet = productItemList.some(item => item.targetAudiences.length > 0);

    setErrors({ colorErrorText: isAtLeastOneColorSet && !productItem.color ? REQUIRED_ERROR : '' });
    setErrors({ sizeErrorText: isAtLeastOneSizeSet && !productItem.size ? REQUIRED_ERROR : '' });
    setErrors({
      targetAudiencesErrorText:
        isAtLeastOneTargetAudiencesSet && productItem.targetAudiences.length === 0 ? REQUIRED_ERROR : '',
    });
  };

  useEffect(() => {
    if (isSubmit) {
      validatePrice(productItem.purchasePrice, 'purchasePriceErrorText');
      validatePrice(productItem.sellingPrice, 'sellingPriceErrorText');
      if (!isCurrentCatalogAccessories()) {
        validateColorOrSize(productItem.color, 'colorErrorText');
        validateColorOrSize(productItem.size, 'sizeErrorText');
        validateTargetAudiences(productItem.targetAudiences);
      } else {
        validateFacultativeAttributeCoherence();
      }
      setIsSubmit(false);
    }
  }, [isSubmit]);

  const handleChange = (index, value, attribute) => {
    onChange(index, { ...productItem, [attribute]: value });
    switch (attribute) {
      case 'color':
        setErrors({ colorErrorText: '' });
        break;
      case 'size':
        setErrors({ sizeErrorText: '' });
        break;
      case 'purchasePrice':
        setErrors({ purchasePriceErrorText: '' });
        break;
      case 'sellingPrice':
        setErrors({ sellingPriceErrorText: '' });
        break;
      case 'targetAudiences':
        setErrors({ targetAudiencesErrorText: '' });
        break;
      default:
        return;
    }
  };

  const setQuantity = quantity => {
    handleChange(index, quantity, 'quantity');
  };

  const isCurrentCatalogAccessories = () => {
    return currentCatalog && currentCatalog.toLowerCase() === 'accessoirs';
  };

  const getLabel = text => {
    return isCurrentCatalogAccessories() ? `${text} (facultatif)` : text;
  };

  return (
    <Box mb="md">
      <Indicator
        color="transparent"
        label={
          index !== 0 && <IconTrash color="red" sx={{ cursor: 'pointer' }} onClick={() => removeProductItem(index)} />
        }
      >
        <Box p="sm" sx={{ border: '1px dashed #E5E4E2', borderRadius: '5px' }}>
          <Grid>
            <Grid.Col xs={6}>
              <Select
                data={sizes}
                label={getLabel('Taille')}
                placeholder="xs"
                maxDropdownHeight={160}
                searchable
                clearable
                withinPortal
                withAsterisk={!isCurrentCatalogAccessories()}
                nothingFound="Rien trouvé"
                transitionProps={{ duration: 150, transition: 'pop-top-left', timingFunction: 'ease' }}
                value={productItem.size}
                onChange={value => handleChange(index, value, 'size')}
                error={sizeErrorText}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Select
                data={colors}
                label={getLabel('Couleur')}
                placeholder="rouge"
                maxDropdownHeight={160}
                withinPortal
                withAsterisk={!isCurrentCatalogAccessories()}
                searchable
                clearable
                nothingFound="Rien trouvé"
                transitionProps={{ duration: 150, transition: 'pop-top-left', timingFunction: 'ease' }}
                value={productItem.color}
                onChange={value => handleChange(index, value, 'color')}
                error={colorErrorText}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col xs={6}>
              <NumberInput
                label="Prix d'achat"
                placeholder="10000"
                withAsterisk
                rightSection={
                  <Text color="dimmed" size="sm">
                    FG
                  </Text>
                }
                value={productItem.purchasePrice}
                onChange={value => handleChange(index, value, 'purchasePrice')}
                error={purchasePriceErrorText}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <NumberInput
                label="Prix de vente"
                placeholder="12000"
                withAsterisk
                rightSection={
                  <Text color="dimmed" size="sm">
                    FG
                  </Text>
                }
                value={productItem.sellingPrice}
                onChange={value => handleChange(index, value, 'sellingPrice')}
                error={sellingPriceErrorText}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col xs={6}>
              <Text size="sm">Quantité</Text>
              <QuantityInput min={1} max={100} quantity={productItem.quantity} setQuantity={setQuantity} />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Checkbox.Group
                label={getLabel('Genre')}
                withAsterisk={!isCurrentCatalogAccessories()}
                value={productItem.targetAudiences}
                onChange={value => handleChange(index, value, 'targetAudiences')}
                error={targetAudiencesErrorText}
              >
                <Group mt="xs">
                  <Checkbox value="Men" label="Home" />
                  <Checkbox value="Women" label="Femme" />
                  <Checkbox value="Kids" label="Enfant" />
                </Group>
              </Checkbox.Group>
            </Grid.Col>
          </Grid>
        </Box>
      </Indicator>
    </Box>
  );
};

export default ProductItem;
