import { create } from 'zustand';
import { devtoolsPersistMiddleware } from './cart.store';

const useSettingsStore = create(
  devtoolsPersistMiddleware(set => ({
    darkMode: false,
    toggleDarkMode: () => set(state => ({ darkMode: !state.darkMode })),
  }))
);

export default useSettingsStore;
