import AppShell from '../../layouts/AppShell';
import { Box, Button, Flex, LoadingOverlay, Menu, Text } from '@mantine/core';
import { IconFileDescription, IconPlus } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import DataTable from '../../components/DataTable';
import { riderColumns } from './riderColumns';
import { createRider, getRidersForTable } from '../../services/rider.service';
import CreateRider from './pages/CreateRider';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useOptimisticMutation from '../Products/hooks/useOptimisticMutation';
import useAuthStore from '../../store/auth.store';

const queryKey = ['riders'];

const Riders = () => {
  const token = useAuthStore(state => state.token);
  const navigate = useNavigate();
  const [openRiderModal, handleRiderModal] = useDisclosure(false);

  const {
    isLoading,
    isSuccess,
    data: riders,
  } = useQuery({
    queryKey,
    queryFn: () => getRidersForTable(token),
    staleTime: 24 * 60 * 60 * 1000,
  });

  const submitRider = async values => {
    return createRider(token, values);
  };

  const optimisticUpdaterFn = (riders, newRider) => [...riders, formatNewRider(newRider)];

  const formatNewRider = ({ firstname, lastname, nickname, phone, city, districtsServed }) => {
    return {
      name: `${firstname} ${lastname}`,
      nickname,
      phone,
      city,
      districtsServed,
      numberOfDeliveries: 0,
    };
  };

  const { mutate: addNewRider } = useOptimisticMutation(queryKey, submitRider, optimisticUpdaterFn);

  const renderRowActionMenuItems = ({ row }) => {
    return (
      <>
        <Menu.Label>Détails</Menu.Label>
        <Menu.Item icon={<IconFileDescription size={14} />} onClick={() => navigate(`/riders/${row.original.id}`)}>
          Voir détails
        </Menu.Item>
      </>
    );
  };
  console.log('riders', riders);

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Livreurs</Text>
        <Button variant="filled" leftIcon={<IconPlus />} onClick={() => handleRiderModal.open()}>
          Ajouter un livreur
        </Button>
      </Flex>

      <Box p={10}>
        {isSuccess && (
          <DataTable
            tableColumns={riderColumns}
            tableData={riders}
            isLoading={isLoading}
            isError={false}
            enableActions={true}
            enableExpanding={false}
            showColumnFilters={false}
            renderRowActionMenuItems={renderRowActionMenuItems}
          />
        )}
      </Box>

      <CreateRider opened={openRiderModal} closeModal={handleRiderModal.close} submit={addNewRider} />
      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default Riders;
