import { create } from 'zustand';
import { devtoolsPersistMiddleware } from './cart.store';

const useAuthStore = create(
  devtoolsPersistMiddleware(set => ({
    currentUser: null,
    token: '',
    loginAction: payload => set({ token: payload.token, currentUser: { ...payload.user } }),
    logoutAction: () => set({ currentUser: null, token: '' }),
  }))
);

export default useAuthStore;
