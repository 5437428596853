import { ActionIcon, Anchor, Container, Header, Image, rem, useMantineColorScheme } from '@mantine/core';
import { IconMoonStars, IconSun } from '@tabler/icons-react';
import { useDefaultNavbarStyles } from './style';
import useSettingsStore from '../../../store/settings.store';
import image from '../../../assets/logo/Logo.png';

export function DefaultNavbar() {
  const { colorScheme } = useMantineColorScheme();
  const { classes } = useDefaultNavbarStyles();

  const toggleDarkMode = useSettingsStore(state => state.toggleDarkMode);

  const handleColor = theme => {
    return {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    };
  };

  const handleDarkMode = () => {
    toggleDarkMode();
  };

  return (
    <Header height={64} mb={20} fixed={true}>
      <Container fluid className={classes.inner}>
        <Anchor href="/home">
          <Image height={rem(48)} width={150} fit="contain" radius="md" src={image} alt="logo" />
        </Anchor>

        <ActionIcon onClick={handleDarkMode} size="lg" sx={handleColor}>
          {colorScheme === 'dark' ? <IconSun size="1.3rem" /> : <IconMoonStars size="1.3rem" />}
        </ActionIcon>
      </Container>
    </Header>
  );
}
