import AppShell from '../../../../layouts/AppShell';
import { Flex, LoadingOverlay, Text } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { renderCRUDRequestNotification } from '../../../../utils/commons';
import { CRUD_ACTION } from '../../../../utils/constants';
import { createProduct } from '../../../../services/product.service';
import ProductEdition from '../../layout/ProductEdition';
import useAuthStore from '../../../../store/auth.store';
import { useQueryClient } from '@tanstack/react-query';

const CreateProduct = () => {
  const token = useAuthStore(state => state.token);
  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const handleSubmit = async ({ name, brandName, description, category, catalog, files, productItems }) => {
    try {
      await createProduct(token, name, brandName, description, category, catalog, files, productItems, id);
      await queryClient.invalidateQueries({ queryKey: ['stock', id] });
      navigate(-1);
      renderCRUDRequestNotification('produit', name, CRUD_ACTION.CREATE, 'success');
    } catch (error) {
      renderCRUDRequestNotification('produit', name, CRUD_ACTION.CREATE, 'error');
    }
  };

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Nouveau produit</Text>
      </Flex>
      <ProductEdition submitProduct={handleSubmit} />
      <LoadingOverlay visible={false} />
    </AppShell>
  );
};

export default CreateProduct;
