import { authenticatedRequest } from '../config/axios.config';
import { makeRequest } from './api.service';

export const getRiders = async token => {
  return makeRequest(() => authenticatedRequest(token).get(`/riders`));
};

export const getRidersForTable = async token => {
  return makeRequest(() => authenticatedRequest(token).get(`/riders/table-list`));
};

export const getRiderDetailById = async (token, riderId) => {
  return makeRequest(() => authenticatedRequest(token).get(`/riders/${riderId}`));
};

export const createRider = async (token, rider) => {
  const { email, ...others } = rider;
  const riderToServer = email ? rider : others;
  return makeRequest(() => authenticatedRequest(token).post(`/riders`, riderToServer));
};
