import { authenticatedRequest } from '../config/axios.config';
import { makeRequest } from './api.service';

export const getStores = async token => {
  return makeRequest(() => authenticatedRequest(token).get(`/stores`));
};

export const createStore = async (token, store) => {
  return makeRequest(() => authenticatedRequest(token).post(`/stores`, store));
};
