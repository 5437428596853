import AppShell from '../../layouts/AppShell';
import { Box, Button, Flex, LoadingOverlay, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import CreateEmployee from './pages/CreateEmployee';
import DataTable from '../../components/DataTable';
import { employeeTableColumns } from './employeeTableColumns';
import { useQuery } from '@tanstack/react-query';
import useOptimisticMutation from '../Products/hooks/useOptimisticMutation';
import useAuthStore from '../../store/auth.store';
import { createEmployee, getAllEmployee } from '../../services/employee.service';

const queryKey = ['employees'];

const Employees = () => {
  const { token } = useAuthStore(state => state);
  const {
    isLoading,
    isSuccess,
    data: employees,
  } = useQuery({
    queryKey,
    queryFn: () => getAllEmployee(token),
    staleTime: 60 * 60 * 1000,
  });
  const [openVendorModal, handleVendorModal] = useDisclosure(false);

  const submitEmployee = async values => {
    return createEmployee(token, values);
  };

  const optimisticUpdaterFn = (employees, newEmployee) => {
    newEmployee.isActive = false;
    return [newEmployee, ...employees];
  };

  const { mutate: onSubmitVendor } = useOptimisticMutation(queryKey, submitEmployee, optimisticUpdaterFn);

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Employés</Text>
        <Button variant="filled" leftIcon={<IconPlus />} onClick={() => handleVendorModal.open()}>
          Ajouter un employé
        </Button>
      </Flex>

      <Box mt="xl">
        {isSuccess && (
          <DataTable
            tableColumns={employeeTableColumns}
            tableData={employees}
            isLoading={isLoading}
            isError={false}
            enableActions={false}
            enableExpanding={false}
            showColumnFilters={false}
          />
        )}
      </Box>

      <CreateEmployee opened={openVendorModal} closeModal={handleVendorModal.close} submit={onSubmitVendor} />
      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default Employees;
