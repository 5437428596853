import { Box, rem, Switch, useMantineTheme } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import useAuthStore from '../../store/auth.store';
import { renderNotification } from '../../utils/commons';

const AccountActivationSwitch = ({ row, handleActivation, queryKey }) => {
  const token = useAuthStore(state => state.token);
  const theme = useMantineTheme();
  const [checked, setChecked] = useState(row.original.isActive);
  const queryClient = useQueryClient();

  const onActivate = async event => {
    const oldChecked = checked;
    const newChecked = event.currentTarget.checked;
    setChecked(newChecked);
    try {
      const response = await handleActivation(token, row.original._id, newChecked);
      await queryClient.invalidateQueries({ queryKey });
      renderNotification(response.message, 'success');
    } catch (error) {
      setChecked(oldChecked);
      console.error(error);
      renderNotification(error.message, 'error');
    }
  };

  return (
    <Box>
      <Switch
        checked={checked}
        onChange={onActivate}
        color="teal"
        size="md"
        disabled={!row.original.isAccountCreated}
        thumbIcon={
          checked ? (
            <IconCheck style={{ width: rem(12), height: rem(12) }} color={theme.colors.teal[6]} stroke={3} />
          ) : (
            <IconX style={{ width: rem(12), height: rem(12) }} color={theme.colors.red[6]} stroke={3} />
          )
        }
      />
    </Box>
  );
};

export default AccountActivationSwitch;
