import AppShell from '../../layouts/AppShell';
import { Box, Button, Flex, LoadingOverlay, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import CreateVendor from './pages/CreateVendor';
import DataTable from '../../components/DataTable';
import { vendorTableColumns } from './vendorTableColumns';
import { useQuery } from '@tanstack/react-query';
import useOptimisticMutation from '../Products/hooks/useOptimisticMutation';
import { createVendor, getAllVendors } from '../../services/vendor.service';
import useAuthStore from '../../store/auth.store';

const queryKey = ['vendors'];

const Vendors = () => {
  const { token, currentUser } = useAuthStore(state => state);
  const {
    isLoading,
    isSuccess,
    data: vendors,
  } = useQuery({
    queryKey,
    queryFn: () => getAllVendors(token),
    staleTime: 60 * 60 * 1000,
  });
  const [openVendorModal, handleVendorModal] = useDisclosure(false);

  const submitVendor = async values => {
    const { domain, ...vendorToServer } = values;
    if (domain) {
      vendorToServer.domain = domain;
    }
    return createVendor(token, vendorToServer);
  };

  const optimisticUpdaterFn = (vendors, newVendor) => {
    const optimisticVendor = { ...newVendor, author: currentUser };
    return [optimisticVendor, ...vendors];
  };

  const { mutate: onSubmitVendor } = useOptimisticMutation(queryKey, submitVendor, optimisticUpdaterFn);

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Vendeurs</Text>
        <Button variant="filled" leftIcon={<IconPlus />} onClick={() => handleVendorModal.open()}>
          Ajouter un vendeur
        </Button>
      </Flex>

      <Box mt="xl">
        {isSuccess && (
          <DataTable
            tableColumns={vendorTableColumns}
            tableData={vendors}
            isLoading={isLoading}
            isError={false}
            enableActions={false}
            enableExpanding={false}
            showColumnFilters={false}
          />
        )}
      </Box>

      <CreateVendor opened={openVendorModal} closeModal={handleVendorModal.close} submit={onSubmitVendor} />
      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default Vendors;
