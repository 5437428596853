import { Box, Button, Group, Modal, TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import {
  GUINEA_PHONE_NUMBER_PATTERN,
  INVALID_PHONE_ERROR,
  NAME_MAX_LENGTH_ERROR,
  NAME_MIN_LENGTH_ERROR,
  REQUIRED_ERROR,
} from '../../../../utils/constants';

function CreateVendor({ opened, closeModal, submit }) {
  const formValidator = Yup.object().shape({
    firstname: Yup.string().min(3, NAME_MIN_LENGTH_ERROR).max(30, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
    lastname: Yup.string().min(3, NAME_MIN_LENGTH_ERROR).max(30, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
    email: Yup.string().email().required(REQUIRED_ERROR),
    phone: Yup.string().matches(GUINEA_PHONE_NUMBER_PATTERN, INVALID_PHONE_ERROR).required(REQUIRED_ERROR),
  });

  const form = useForm({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
    },
    validate: yupResolver(formValidator),
  });

  const handleSubmit = values => {
    submit(values);
    form.reset();
    closeModal();
  };

  return (
    <Modal
      title={
        <span>
          <h3>Nouveau vendeur</h3>
        </span>
      }
      opened={opened}
      onClose={() => closeModal()}
      keepMounted={false}
    >
      <Box component="form" maw={400} mx="auto" onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput label="Prénom" placeholder="Oumar" withAsterisk {...form.getInputProps('firstname')} />
        <TextInput label="Nom" placeholder="Diakhaby" mt="md" withAsterisk {...form.getInputProps('lastname')} />
        <TextInput
          label="Email"
          placeholder="john.doe@gmail.com"
          mt="md"
          withAsterisk
          {...form.getInputProps('email')}
        />
        <TextInput label="Téléphone" placeholder="620765434" mt="md" withAsterisk {...form.getInputProps('phone')} />

        <Group position="right" mt="md">
          <Button type="submit">Valider</Button>
        </Group>
      </Box>
    </Modal>
  );
}

CreateVendor.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default CreateVendor;
