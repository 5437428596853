import { Box, Button, Group, Modal, MultiSelect, TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import {
  DISTRICT_ARRAY_NOT_EMPTY_ERROR,
  GUINEA_PHONE_NUMBER_PATTERN,
  INVALID_PHONE_ERROR,
  NAME_MAX_LENGTH_ERROR,
  NAME_MIN_LENGTH_ERROR,
  REQUIRED_ERROR,
} from '../../../../utils/constants';
import { useState } from 'react';

function CreateRider({ opened, closeModal, submit }) {
  const [data, setData] = useState([]);
  const formValidator = Yup.object().shape({
    firstname: Yup.string().min(3, NAME_MIN_LENGTH_ERROR).max(30, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
    lastname: Yup.string().min(3, NAME_MIN_LENGTH_ERROR).max(30, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
    nickname: Yup.string().max(30, NAME_MAX_LENGTH_ERROR),
    email: Yup.string().email(),
    phone: Yup.string().matches(GUINEA_PHONE_NUMBER_PATTERN, INVALID_PHONE_ERROR).required(REQUIRED_ERROR),
    city: Yup.string().min(3, NAME_MIN_LENGTH_ERROR).max(30, NAME_MAX_LENGTH_ERROR).required(REQUIRED_ERROR),
    districtsServed: Yup.array().of(Yup.string().required(REQUIRED_ERROR)).min(1, DISTRICT_ARRAY_NOT_EMPTY_ERROR),
  });

  const form = useForm({
    initialValues: {
      firstname: '',
      lastname: '',
      nickname: '',
      email: '',
      phone: '',
      city: '',
      districtsServed: [],
    },
    validate: yupResolver(formValidator),
  });

  const handleSubmit = values => {
    submit(values);
    form.reset();
    closeModal();
  };

  return (
    <Modal
      title={
        <span>
          <h3>Nouveau Livreur</h3>
        </span>
      }
      opened={opened}
      onClose={() => closeModal()}
      keepMounted={false}
    >
      <Box component="form" maw={400} mx="auto" onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput label="Prénom" placeholder="Oumar" withAsterisk {...form.getInputProps('firstname')} />
        <TextInput label="Nom" placeholder="Diakhaby" mt="md" withAsterisk {...form.getInputProps('lastname')} />
        <TextInput label="Surnom" placeholder="Griezman" mt="md" {...form.getInputProps('nickname')} />
        <TextInput label="Email" placeholder="john.doe@gmail.com" mt="md" {...form.getInputProps('email')} />
        <TextInput label="Téléphone" placeholder="620765434" mt="md" withAsterisk {...form.getInputProps('phone')} />
        <TextInput label="Ville" placeholder="Conakry" mt="md" withAsterisk {...form.getInputProps('city')} />
        <MultiSelect
          label="Quartiers desservis"
          data={data}
          placeholder="Lambanyi"
          mt="md"
          withAsterisk
          searchable
          creatable
          getCreateLabel={query => `Ajouter '${query}'`}
          onCreate={query => {
            const item = { value: query, label: query };
            setData(current => [...current, item]);
            return item;
          }}
          {...form.getInputProps('districtsServed')}
        />
        <Group position="right" mt="md">
          <Button type="submit">Valider</Button>
        </Group>
      </Box>
    </Modal>
  );
}

CreateRider.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default CreateRider;
