import { createStyles, rem } from '@mantine/core';

export const useDefaultNavbarStyles = createStyles(theme => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: rem(63),
    width: '100%',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    paddingLeft: rem(10),
    paddingRight: rem(20),
  },
}));
