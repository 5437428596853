import { BASE_URL } from '../../../../config/axios.config';
import { ActionIcon, Box, Divider, Flex, Group, Image, Stack, Text } from '@mantine/core';
import { formatCurrency, renderConfirmModal } from '../../../../utils/commons';
import { IconMinus, IconPlus, IconTrash } from '@tabler/icons-react';
import useCart from '../../hooks/useCart';
import { useNavigate } from 'react-router-dom';

const ProductItemLine = ({ productItem, quantity, indexInArray, isFromCart }) => {
  const navigate = useNavigate();
  const { cartLineProductList, removeProductItemFromCart, changeQuantity } = useCart();
  const imageUrl = `${BASE_URL}/files/single/${productItem.product.files[0].name}`;
  const totalSellPrice = productItem.sellingPrice * quantity;

  const increaseCart = productItemId => {
    changeQuantity(productItemId, quantity + 1);
  };

  const decreaseCart = productItemId => {
    if (quantity === 1) {
      const modalText = (
        <div>
          <Text>
            Etes-vous sûr de vouloir supprimer le fichier <b>{`${productItem.product.name} ?`}</b>
          </Text>
        </div>
      );

      const confirmFunction = () => {
        const numberOfProductInCart = cartLineProductList.length;
        removeProductItemFromCart(productItemId);
        if (numberOfProductInCart === 1) {
          navigate('/products');
        }
      };
      renderConfirmModal(productItemId, modalText, confirmFunction);
    } else {
      changeQuantity(productItemId, quantity - 1);
    }
  };

  return (
    <Box>
      {indexInArray > 0 && <Divider mt={10} mb={10} variant="dashed" />}
      <Group spacing={0}>
        <Group w="15%">
          <Image height={50} width={50} radius="md" src={imageUrl} />
        </Group>
        <Box w="35%">
          <Stack spacing={0}>
            <Text size="md">{productItem.product.name}</Text>
            <Text color="dimmed">{productItem.sku}</Text>
          </Stack>
        </Box>
        <Box w="8%">
          <Flex h="100%" align="center" justify="center">
            {productItem.size}
          </Flex>
        </Box>
        <Box w="10%">
          <Flex h="100%" align="center" justify="center">
            {productItem.color && productItem.color.name}
          </Flex>
        </Box>
        <Box w="8%">
          <Flex h="100%" align="center" justify="center">
            x{quantity}
          </Flex>
        </Box>
        <Box w="20%">
          <Flex h="100%" justify="flex-end">
            {formatCurrency(totalSellPrice)} FG
          </Flex>
        </Box>
        {isFromCart && (
          <Stack w="4%" spacing={2} align="end">
            <ActionIcon variant="filled" color="red" onClick={() => decreaseCart(productItem.productItemId)}>
              {quantity === 1 && <IconTrash size="1.2rem" />}
              {quantity > 1 && <IconMinus size="1.2rem" />}
            </ActionIcon>
            <ActionIcon
              variant="filled"
              color="green"
              disabled={productItem.remainingQuantity <= quantity}
              onClick={() => increaseCart(productItem.productItemId)}
            >
              <IconPlus size="1.2rem" />
            </ActionIcon>
          </Stack>
        )}
      </Group>
    </Box>
  );
};

ProductItemLine.defaultProps = {};

export default ProductItemLine;
