import AppShell from '../../layouts/AppShell';
import { Button, Container, Flex, Grid, Group, LoadingOverlay, rem, Text, Title } from '@mantine/core';
import { IconBasketPlus, IconPlus } from '@tabler/icons-react';
import CreateStock from './pages/CreateStock';
import { useDisclosure } from '@mantine/hooks';
import { createStock, getStocks } from '../../services/stock.service';
import StockCard from './components/StockCard';
import { usePersonalOrdersStyles } from './style';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useOptimisticMutation from '../Products/hooks/useOptimisticMutation';
import { ROLE } from '../../utils/constants';
import useAuthStore from '../../store/auth.store';

const queryKey = ['stocks'];

const Stocks = () => {
  const { classes } = usePersonalOrdersStyles();

  const { token, currentUser } = useAuthStore(state => state);
  const navigate = useNavigate();

  const [openStockModal, handleStockModal] = useDisclosure(false);

  const {
    isSuccess,
    isLoading,
    data: stocks,
  } = useQuery({
    queryKey,
    queryFn: () => getStocks(token),
    staleTime: 60 * 60 * 1000,
  });

  const submitStock = async values => {
    return createStock(token, values.name);
  };

  const optimisticUpdaterFn = (stocks, stock) => {
    return [
      {
        _id: 10,
        name: stock.name,
        createdAt: Date.now(),
        numberOfProductsInStock: 0,
        numberOfProductsSold: 0,
        totalExpenses: 0,
        totalIncome: 0,
      },
      ...stocks,
    ];
  };

  const { mutate: onSubmitStock } = useOptimisticMutation(queryKey, submitStock, optimisticUpdaterFn);

  return (
    <AppShell>
      <Flex justify="space-between" align="center" direction="row" p={5}>
        <Text className="pageTitle">Stocks</Text>
        {currentUser.role === ROLE.VENDOR && (
          <Group position="apart">
            <Button variant="filled" leftIcon={<IconPlus />} onClick={() => handleStockModal.open()}>
              Ajouter un stock
            </Button>
          </Group>
        )}
      </Flex>

      {/* Projects */}
      {isSuccess && stocks.length === 0 && (
        <Container className={classes.root}>
          <div className={classes.label}>
            <IconBasketPlus size={rem(180)} />
          </div>
          <Title className={classes.title}>Vous n'avez pas encore de stock</Title>
          <Group position="center">
            <Button variant="subtle" size="md" onClick={() => handleStockModal.open()}>
              Ajouter une nouveau stock
            </Button>
          </Group>
        </Container>
      )}
      <Grid gutter="lg" align="stretch" p={10}>
        {isSuccess &&
          stocks.map(
            ({ _id, name, createdAt, numberOfProductsInStock, numberOfProductsSold, totalExpenses, totalIncome }) => (
              <Grid.Col md={4} lg={4} xs={6} onClick={() => navigate(`/stocks/${_id}`)} key={_id}>
                <StockCard
                  name={name}
                  createdAt={createdAt}
                  numberOfProductsInStock={numberOfProductsInStock}
                  numberOfProductsSold={numberOfProductsSold}
                  totalExpenses={totalExpenses}
                  totalIncome={totalIncome}
                />
              </Grid.Col>
            )
          )}
      </Grid>

      <CreateStock opened={openStockModal} closeModal={handleStockModal.close} submit={onSubmitStock} />
      <LoadingOverlay visible={isLoading} />
    </AppShell>
  );
};

export default Stocks;
