import axios from 'axios';
import useAuthStore from '../store/auth.store';
import { renderNotification } from '../utils/commons';
import useCartStore from '../store/cart.store';

// export const BASE_URL = 'http://localhost:8085/api';
// export const BASE_URL = 'http://localhost:4300/api'; // DOCKER
export const BASE_URL = 'https://api.vendeur-heureux.com/api';

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const publicDownloadFile = axios.create({
  baseURL: BASE_URL,
  responseType: 'blob',
});

export const authenticatedRequest = token => {
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: token ? { Authorization: `Bearer ${token}` } : {},
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      if (axios.isAxiosError(error)) {
        const response = error.response;

        if (response && response.status === 600) {
          // Use the Zustand logout action
          const logoutAction = useAuthStore.getState().logoutAction;
          const emptyCart = useCartStore().getState().emptyCart;
          if (logoutAction && emptyCart) {
            renderNotification(response.data.message, 'error');
            emptyCart();
            logoutAction();
          }
        }
      }

      // Always rethrow the error so that it can be handled elsewhere
      throw error;
    }
  );
  return instance;
};
