import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export const devtoolsPersistMiddleware = f => devtools(persist(f, { name: 'devtoolsPersistMiddleware' }));

// @ts-ignore
export const devtoolsMiddleware = f => devtools(f, { name: 'devtoolsPersistMiddleware' });

const useCartStore = create(
  // @ts-ignore
  devtoolsPersistMiddleware(set => ({
    cartLineList: [],
    addProductItemToCart: (productItemId, quantity) =>
      set(state => {
        const cartLineIndex = state.cartLineList.findIndex(cartLine => cartLine.productItemId === productItemId);
        if (cartLineIndex < 0) {
          const cartLine = {
            productItemId,
            quantity,
          };
          return { cartLineList: [...state.cartLineList, cartLine] };
        } else {
          const newCartLineList = [...state.cartLineList];
          newCartLineList[cartLineIndex].quantity += 1;
          return { cartLineList: newCartLineList };
        }
      }),
    removeProductItemFromCart: productItemId =>
      set(state => {
        const newCartLineList = state.cartLineList.filter(cartLine => !(cartLine.productItemId === productItemId));
        return { cartLineList: newCartLineList };
      }),
    changeQuantity: (productItemId, quantity) =>
      set(state => {
        const cartLineIndex = state.cartLineList.findIndex(cartLine => cartLine.productItemId === productItemId);
        const newCartLineList = [...state.cartLineList];
        newCartLineList[cartLineIndex].quantity = quantity;
        return { cartLineList: newCartLineList };
      }),
    emptyCart: () => set(() => ({ cartLineList: [] })),
  }))
);

export default useCartStore;
