// Define a generic function for making Axios requests

export async function makeRequest(requestFunction) {
  try {
    const response = await requestFunction();
    return response.data;
  } catch (error) {
    // If it's an AxiosError, throw the response data, otherwise rethrow the error
    if (error.isAxiosError !== undefined) {
      throw error.response?.data;
    } else {
      throw error;
    }
  }
}
