import { dateToFrenchFormat, dateToFrenchLongFormat, timeFromDate } from '../../utils/date-convert';
import { formatCurrency, formatPhoneNumber, formatToGuineaCurrency, getAuthorName } from '../../utils/commons';
import { Box, Stack, Text } from '@mantine/core';

export const expenseTableColumns = (totalAmount, currentUserId) => [
  {
    accessorKey: 'type', //access nested data with dot notation
    header: 'Type',
    size: 100,
  },
  {
    accessorKey: 'description', //access nested data with dot notation
    header: 'Description',
  },
  {
    header: 'Auteur',
    Cell: ({ renderedCellValue, row }) => (
      <Stack spacing={0}>
        <Text>{getAuthorName(row.original.author, currentUserId)}</Text>
        <Text color="dimmed">{formatPhoneNumber(row.original.author.phone)}</Text>
      </Stack>
    ),
  },
  {
    accessorKey: 'createdAt', //access nested data with dot notation
    header: 'Date',
    size: 100,
    Cell: ({ renderedCellValue, row }) => (
      <Stack spacing={0}>
        <Text>{dateToFrenchLongFormat(row.original.createdAt)}</Text>
        <Text color="dimmed">{timeFromDate(row.original.createdAt)}</Text>
      </Stack>
    ),
  },
  {
    accessorKey: 'stock', //access nested data with dot notation
    header: 'Source',
    size: 80,
    Footer: () => <Box>Total :</Box>,
  },
  {
    accessorKey: 'amount', //access nested data with dot notation
    header: 'Montant',
    size: 80,
    Cell: ({ renderedCellValue, row }) => formatToGuineaCurrency(row.original.amount),
    Footer: () => <Box>{formatCurrency(totalAmount)} FG</Box>,
  },
];
