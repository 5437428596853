import { makeRequest } from './api.service';
import { authenticatedRequest } from '../config/axios.config';

export const createEmployee = (token, employee) => {
  return makeRequest(() => authenticatedRequest(token).post(`/employee-administration`, employee));
};

export const getAllEmployee = token => {
  return makeRequest(() => authenticatedRequest(token).get(`/employee-administration`));
};

export const handleEmployeeActivation = (token, employeeId, isActive) => {
  return makeRequest(() =>
    authenticatedRequest(token).post(`/employee-administration/handle-activation`, { isActive, employeeId })
  );
};
