import { useEffect, useState } from 'react';
import useCartStore from '../../../store/cart.store';
import { getProductItemListByIds } from '../../../services/product.service';
import { renderNotification } from '../../../utils/commons';
import useAuthStore from '../../../store/auth.store';

const useCart = () => {
  const token = useAuthStore(state => state.token);
  const { cartLineList, removeProductItemFromCart, addProductItemToCart, changeQuantity, emptyCart } = useCartStore(
    state => state
  );

  const [cartLineProductList, setCartLineProductList] = useState([]);
  const [cartTotalQuantity, setCartTotalQuantity] = useState(0);
  const [cartTotalPrice, setCartTotalPrice] = useState(0);

  const getCartTotalQuantity = cartLineList => {
    if (cartLineList.length === 0) return 0;

    return cartLineList.reduce((sum, cartLine) => sum + cartLine.quantity, 0);
  };

  const getCartTotalPrice = cartLineProductList => {
    if (cartLineProductList.length === 0) return 0;

    return cartLineProductList.reduce(
      (sum, cartLineProduct) => sum + cartLineProduct.productItem.sellingPrice * cartLineProduct.quantity,
      0
    );
  };

  useEffect(() => {
    const getProducts = async () => {
      // Get Products in cart
      const productIds = cartLineList.map(cartLine => cartLine.productItemId);
      if (productIds.length > 0) {
        try {
          const productItemList = await getProductItemListByIds(token, productIds);

          // Map product to cart Items
          const cartLineProductList = cartLineList.map(cartLine => {
            const productItem = productItemList.find(
              productItem => productItem.productItemId === cartLine.productItemId
            );
            if (productItem && productItem.remainingQuantity > 0) {
              return {
                productItem,
                quantity: cartLine.quantity,
              };
            } else {
              removeProductItemFromCart(cartLine.productItemId);
            }
          });
          setCartLineProductList(cartLineProductList);
          setCartTotalQuantity(getCartTotalQuantity(cartLineList));
          setCartTotalPrice(getCartTotalPrice(cartLineProductList));
        } catch (error) {
          console.error(error);
          renderNotification(error.message, 'error');
        }
      }
    };
    getProducts();
    // }
  }, [cartLineList]);

  return {
    cartLineProductList,
    cartTotalQuantity,
    cartTotalPrice,
    addProductItemToCart,
    removeProductItemFromCart,
    changeQuantity,
    emptyCart,
  };
};

export default useCart;
